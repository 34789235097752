import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonScale, ButtonKind, ButtonAppearance } from 'bloko/blocks/button';
import NotificationFooter from 'bloko/blocks/notificationManager/NotificationFooter';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatNewLine } from 'bloko/common/trl';

import translation from 'src/components/translation';

export enum Kind {
    ContactInformationViewGeneric = 'contactInformationViewGeneric',
    QuotaExceeded = 'quotaExceeded',
    ResumeCountryMismatch = 'resumeCountryMismatch',
    ResumeContentIsTooLarge = 'resumeContentIsTooLarge',
    ResumeModerationCorrected = 'resumeModerationCorrected',
    ContactInformationViewInfo = 'contactInformationViewInfo',
}

interface PartialTrls {
    heading: string;
    footer: string;
    text: string;
}

const TrlKeys = {
    [Kind.ContactInformationViewGeneric]: {
        heading: 'resume.contacts.notification.civ.error.heading',
        text: 'resume.contacts.notification.civ.error.content',
    },
    [Kind.QuotaExceeded]: {
        heading: 'resume.contacts.notification.civ.quotaExceeded.heading',
        text: 'resume.contacts.notification.civ.quotaExceeded.content',
    },
    [Kind.ResumeCountryMismatch]: {
        heading: 'resume.contacts.notification.civ.resumeCountryMismatch.heading',
        text: 'resume.contacts.notification.civ.resumeCountryMismatch.content',
    },
    [Kind.ResumeContentIsTooLarge]: {
        text: 'resume.contacts.notification.civ.resumeContentIsTooLarge.content',
    },
    [Kind.ResumeModerationCorrected]: {
        text: 'rb.moderationCorrectedNotification',
    },
    [Kind.ContactInformationViewInfo]: {
        heading: 'resume.contacts.notification.civ.info.heading',
        text: 'resume.contacts.notification.civ.info.content',
        footer: 'resume.contacts.notification.civ.button.to.price',
    },
};

interface NotificationTemplateProps {
    kind: Kind;
    message?: string;
    hasHeading?: boolean;
    hasFooter?: boolean;
    footerLink?: string;
}

const NotificationTemplate: TranslatedComponent<NotificationTemplateProps> = ({
    trls,
    kind,
    message,
    hasHeading,
    hasFooter,
    footerLink,
}) => (
    <>
        {hasHeading && (
            <NotificationHeading>{formatNewLine(trls[(TrlKeys[kind] as PartialTrls).heading])}</NotificationHeading>
        )}
        {message || trls[(TrlKeys[kind] as PartialTrls).text]}
        {hasFooter && footerLink && (
            <NotificationFooter>
                <Button
                    scale={ButtonScale.Small}
                    kind={ButtonKind.Primary}
                    appearance={ButtonAppearance.Outlined}
                    Element={Link}
                    to={footerLink}
                >
                    {trls[(TrlKeys[kind] as PartialTrls).footer]}
                </Button>
            </NotificationFooter>
        )}
    </>
);

export default translation(NotificationTemplate);
