import { useState } from 'react';

import ConversionNumber from 'bloko/blocks/conversion';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { RelocationArea } from 'src/models/resume/resumeCommon.types';

interface ResumeAreaWithDistrictsProps {
    area: RelocationArea;
    withToggle?: boolean;
}

const TrlKeys = {
    allDistricts: 'resume.allDistricts',
    showMore: 'resume.district.showMore',
    showMoreOne: 'resume.district.showMore.one',
    showMoreSome: 'resume.district.showMore.some',
    showMoreMany: 'resume.district.showMore.many',
    showLess: 'resume.district.showLess',
};

const DEFAULT_NUMBER_DISTRICTS_TO_SHOW = 4;

const cutAreaDistricts = (area: RelocationArea, numDistricts: number) => {
    return area.districts.slice(0, numDistricts);
};

const ResumeAreaWithDistricts: TranslatedComponent<ResumeAreaWithDistrictsProps> = ({
    trls,
    area,
    withToggle = true,
}) => {
    const initialNumberOfDistricts = withToggle ? DEFAULT_NUMBER_DISTRICTS_TO_SHOW : area.districts.length;
    const [visibleDistricts, setVisibleDistricts] = useState(cutAreaDistricts(area, initialNumberOfDistricts));
    const allDistrictsVisible = area.districts.length === visibleDistricts.length;

    return (
        <>
            <Text Element="p">{area.title}</Text>
            <Text Element="span" size={TextSize.Small} importance={TextImportance.Tertiary}>
                {area.hasAllDistricts
                    ? trls[TrlKeys.allDistricts]
                    : visibleDistricts.map((district) => district.title).join(', ')}
            </Text>
            {withToggle && !area.hasAllDistricts && area.districts.length > DEFAULT_NUMBER_DISTRICTS_TO_SHOW && (
                <Text Element="span" size={TextSize.Small}>
                    {' '}
                    <Link
                        appearance={LinkAppearance.Pseudo}
                        onClick={() => {
                            const numDistricts = allDistrictsVisible
                                ? DEFAULT_NUMBER_DISTRICTS_TO_SHOW
                                : area.districts.length;
                            const districts = cutAreaDistricts(area, numDistricts);
                            setVisibleDistricts(districts);
                        }}
                    >
                        {allDistrictsVisible
                            ? trls[TrlKeys.showLess]
                            : formatToReactComponent(trls[TrlKeys.showMore], {
                                  '{0}': (
                                      <ConversionNumber
                                          one={trls[TrlKeys.showMoreOne]}
                                          some={trls[TrlKeys.showMoreSome]}
                                          many={trls[TrlKeys.showMoreMany]}
                                          value={area.districts.length - visibleDistricts.length}
                                      />
                                  ),
                              })}
                    </Link>
                </Text>
            )}
        </>
    );
};

export default translation(ResumeAreaWithDistricts);
