import Analytics from '@hh.ru/analytics-js';

import TalantixResumeImportFailedNotification from 'src/components/Notifications/TalantixResumeImportFailedNotification';
import TalantixResumeImportedNotification from 'src/components/Notifications/TalantixResumeImportedNotification';
import TalantixResumeImportedNotificationPromo from 'src/components/Notifications/TalantixResumeImportedNotificationPromo';

export const talantixResumeImported = {
    Element: TalantixResumeImportedNotification,
    kind: 'ok',
    onClose: () => {
        Analytics.sendEvent('talantix', 'import_success', 'close_saved');
    },
};

export const talantixResumeImportFailed = {
    Element: TalantixResumeImportFailedNotification,
    kind: 'error',
    onClose: (_, { type }) => {
        Analytics.sendEvent('talantix', 'import_error', `close_error_${type}`);
    },
};

export const talalantixResumeImportedPromo = {
    Element: TalantixResumeImportedNotificationPromo,
    kind: 'info',
    onClose: (_, { onClose }) => {
        onClose();
        Analytics.sendEvent('talantix', 'import_promo', 'close');
    },
};
