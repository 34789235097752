import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Column from 'bloko/blocks/column';

import HOCExperiment from 'src/components/HOCExperiment';
import { selectorMColumnSize } from 'src/components/HiringManager/utils/selectorMColumnSize';
import translation from 'src/components/translation';

import ResumeBlock from 'src/pages/ResumeView/components/View/ResumeBlock';
import ResumeEditLink from 'src/pages/ResumeView/components/View/ResumeEditLink';
import ResumeEducationBody from 'src/pages/ResumeView/components/View/ResumeEducationBody';

class ResumeEducation extends PureComponent {
    getTitle = () => {
        const { primaryEducation, elementaryEducation, educationLevel, trls } = this.props;

        if (primaryEducation.length || elementaryEducation.length) {
            return trls[ResumeEducation.trls[`educationLevel.${educationLevel}`]];
        }

        return trls[ResumeEducation.trls.education];
    };

    renderEducationContent = () => {
        const { primaryEducation, elementaryEducation, educationLevel, trls, mColumnSize } = this.props;
        const { mMain } = mColumnSize;

        if (!primaryEducation.length && !elementaryEducation.length && educationLevel) {
            return (
                <Column xs="4" s="8" m={mMain} l="12">
                    <div className="resume-block-container">
                        <p>{trls[ResumeEducation.trls[`educationLevel.${educationLevel}`]]}</p>
                    </div>
                </Column>
            );
        }

        if (educationLevel === 'secondary') {
            return elementaryEducation.map((education) => (
                <ResumeEducationBody key={education.id} showOrganization={false} {...education} />
            ));
        }
        return primaryEducation.map((education) => <ResumeEducationBody key={education.id} {...education} />);
    };

    render() {
        return (
            <ResumeBlock
                title={this.getTitle()}
                data-qa="resume-block-education"
                EditLink={
                    <ResumeEditLink block="education" field="primaryEducation" data-qa="resume-block-education-edit" />
                }
            >
                {this.renderEducationContent()}
            </ResumeBlock>
        );
    }
}

ResumeEducation.propTypes = {
    primaryEducation: PropTypes.array.isRequired,
    elementaryEducation: PropTypes.array.isRequired,
    educationLevel: PropTypes.string.isRequired,
    trls: PropTypes.object,
    mColumnSize: PropTypes.object.isRequired,
};

ResumeEducation.trls = {
    education: 'rb.short.header.education',
    'educationLevel.higher': 'resume.block.education.level.higher',
    'educationLevel.bachelor': 'resume.block.education.level.bachelor',
    'educationLevel.master': 'resume.block.education.level.master',
    'educationLevel.candidate': 'resume.block.education.level.candidate',
    'educationLevel.doctor': 'resume.block.education.level.doctor',
    'educationLevel.unfinished_higher': 'resume.block.education.level.unfinished_higher',
    'educationLevel.special_secondary': 'resume.block.education.level.special_secondary',
    'educationLevel.secondary': 'resume.block.education.level.secondary',
};

export default connect((state) => ({
    elementaryEducation: state.resume?.elementaryEducation.value,
    primaryEducation: state.resume?.primaryEducation.value,
    educationLevel: state.resume?.educationLevel.value,
    mColumnSize: selectorMColumnSize(state),
}))(translation(HOCExperiment(ResumeEducation)));
