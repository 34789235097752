import { Dispatch, SetStateAction, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FormApi } from 'final-form';

import { LangTrls } from 'bloko/common/hooks/useTranslations';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';
import { resumeCommentAddMessage } from 'src/components/Notifications/ResumeComments';
import { useSelector } from 'src/hooks/useSelector';
import { addCommentToUser } from 'src/models/employer/resume/commentsByUser';
import { addComment, ResumeComment } from 'src/models/employer/resume/resumeComments';
import { HiringManagerCommentFormData } from 'src/pages/ResumeView/components/View/ResumeSidebar/Comments/HiringManagerComment/types';
import fetcher from 'src/utils/fetcher';

const TrlKeys = {
    candidateAccepted: 'resume.comments.hiringManager.candidateAccepted',
    candidateRejected: 'resume.comments.hiringManager.candidateRejected',
};

type UseSendCommentSubmit = ({
    trls,
    setIsExpanded,
    vacancyId,
    resumeHash,
    topicId,
}: {
    trls: LangTrls;
    setIsExpanded?: Dispatch<SetStateAction<boolean>>;
    vacancyId?: string;
    resumeHash?: string;
    topicId?: string;
}) => (data: HiringManagerCommentFormData, form: FormApi<HiringManagerCommentFormData>) => Promise<void>;

export const useSendCommentSubmit: UseSendCommentSubmit = ({
    trls,
    setIsExpanded,
    vacancyId,
    resumeHash,
    topicId,
}): ((data: HiringManagerCommentFormData, form: FormApi<HiringManagerCommentFormData>) => Promise<void>) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const applicantId = useSelector((state) => state.resume?.user);

    const add = useCallback(
        (data: ResumeComment) => {
            if (!applicantId) {
                return;
            }
            dispatch([addComment(data), addCommentToUser({ userId: +applicantId, commentId: data.id })]);
        },
        [dispatch, applicantId]
    );

    return useCallback(
        async (data: HiringManagerCommentFormData, form: FormApi<HiringManagerCommentFormData>): Promise<void> => {
            if (!applicantId) {
                return;
            }
            let trlKey;
            if (data.verdict) {
                trlKey = data.verdict === 'accept' ? 'candidateAccepted' : 'candidateRejected';
            }

            let body = data.text;
            if (data.verdict) {
                const trlVerdict = trls[TrlKeys[trlKey as keyof typeof TrlKeys]];
                body = data.text ? `${trlVerdict}\n\n${data.text}` : trlVerdict;
            }

            try {
                const response = await fetcher.postFormData('/employer/applicant/json_comment', {
                    body,
                    applicantId: +applicantId,
                    action: 'add',
                    accessType: 'COWORKERS',
                    verdict: data.verdict?.toUpperCase(),
                    vacancyId,
                    resumeHash,
                    topicId,
                });
                add(response.data);
                addNotification(resumeCommentAddMessage, { props: { message: response.data.body } });
                form.restart();
                setIsExpanded?.(false);
            } catch (e) {
                defaultRequestErrorHandler(e, addNotification);
            }
        },
        [add, addNotification, applicantId, resumeHash, setIsExpanded, topicId, trls, vacancyId]
    );
};
