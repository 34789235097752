import { memo } from 'react';
import PropTypes from 'prop-types';

import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

const PersonalSitesGroup = ({ name, children, withDeepLink }) => {
    if (withDeepLink) {
        return (
            <div className="resume__contacts-sites-group">
                <Text importance={TextImportance.Tertiary}>{name}</Text>
                <VSpacing base={1} />
                {children}
            </div>
        );
    }

    return children;
};

PersonalSitesGroup.propTypes = {
    children: PropTypes.node,
    name: PropTypes.string,
    withDeepLink: PropTypes.bool,
};

export default memo(PersonalSitesGroup);
