import { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';

import paths from 'src/app/routePaths';
import translation from 'src/components/translation';

const ResumeSuitableVacancies = ({ hash, hasSuitableVacancies, trls }) => {
    if (!hasSuitableVacancies) {
        return null;
    }
    return (
        <Fragment>
            <p>{trls[ResumeSuitableVacancies.trls.text]}</p>
            <BlokoLink disableVisited Element={Link} to={`${paths.vacancySearch}?resume=${hash}`}>
                {trls[ResumeSuitableVacancies.trls.link]}
            </BlokoLink>
        </Fragment>
    );
};

ResumeSuitableVacancies.propTypes = {
    hash: PropTypes.string,
    hasSuitableVacancies: PropTypes.bool,
    trls: PropTypes.object,
};

ResumeSuitableVacancies.trls = {
    text: 'rb.suitable.text',
    link: 'rb.suitable.link',
};

export default connect((state) => ({
    hash: state.resume?.hash,
    hasSuitableVacancies: state.resume?.hasSuitableVacancies,
}))(translation(ResumeSuitableVacancies));
