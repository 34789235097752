import { ReactNode, useCallback } from 'react';
import { NOT_FOUND, BAD_REQUEST, CONFLICT } from 'http-status-codes';

import { Button, Placeholder } from '@hh.ru/magritte-ui';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { Link } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import Attention, { AttentionKind } from 'src/components/Attention';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import useUserTypeForMenu from 'src/hooks/useUserTypeForMenu';
import { SupernovaUserType } from 'src/models/supernovaUserType';
import { UserType } from 'src/models/userType';

const TrlKeys = {
    badRequest: 'error.400.message',
    serviceError: 'resume.service.error',
    applicantNotExists: 'resume.applicant.notexists',
    employerNotExists: 'resume.employer.notexists',
    viewLimit: 'resume.viewLimitExceed.content',
    viewLimitLast: 'resume.viewLimitExceed.contentLast',
    availabilityTitle: 'resume.anonymous.availability.title',
    availabilityDescription: 'resume.anonymous.availability.description',
    signInButton: 'resume.anonymous.availability.button.signIn',
    signUpButton: 'resume.anonymous.availability.button.signUp',
};

interface ResumeErrorProps {
    viewLimit?: string;
}

const ResumeError: TranslatedComponent<ResumeErrorProps> = ({ viewLimit, trls }) => {
    const errorCode = useSelector((state) => state.errorCode);
    const userType = useSelector((state) => state.userType);
    const useApplicantSignInUrl = useUserTypeForMenu(SupernovaUserType.Applicant);
    const userSignInUrl = useSelectorNonNullable((state) => state.authUrl['login-url']);
    const applicantSignUpUrl = useSelectorNonNullable((state) => state.authUrl['signup-form']);
    const employerSignUpUrl = useSelectorNonNullable((state) => state.authUrl['auth-employer-url']);
    const isMobile = [Breakpoint.XS, Breakpoint.S].includes(useBreakpoint());
    const staticHost = useSelector((state) => state.config.staticHost);

    const renderErrorMessage = useCallback(() => {
        let errorMessage: string | ReactNode = trls[TrlKeys.serviceError];

        if (errorCode === NOT_FOUND) {
            if (userType === UserType.Anonymous) {
                return (
                    <Placeholder
                        maxWidth={343}
                        paddingTop={isMobile ? 100 : 90}
                        imageHeight={56}
                        image={`${staticHost}/images/hh/anonymous/authorize.svg`}
                        title={trls[TrlKeys.availabilityTitle]}
                        description={trls[TrlKeys.availabilityDescription]}
                        buttonPrimary={
                            <Button mode="primary" style="accent" Element={Link} to={userSignInUrl}>
                                {trls[TrlKeys.signInButton]}
                            </Button>
                        }
                        buttonSecondary={
                            <Button
                                mode="secondary"
                                style="accent"
                                Element={Link}
                                to={useApplicantSignInUrl ? applicantSignUpUrl : employerSignUpUrl}
                            >
                                {trls[TrlKeys.signUpButton]}
                            </Button>
                        }
                    />
                );
            }

            if (userType === UserType.Applicant || userType === UserType.NedoUser) {
                errorMessage = trls[TrlKeys.applicantNotExists];
            }

            if (userType === UserType.Employer) {
                errorMessage = trls[TrlKeys.employerNotExists];
            }
        }

        if (errorCode === BAD_REQUEST) {
            errorMessage = trls[TrlKeys.badRequest];
        }

        if (errorCode === CONFLICT && userType === UserType.Employer && viewLimit) {
            errorMessage = (
                <>
                    <p>{format(trls[TrlKeys.viewLimit], { '{0}': viewLimit })}</p>
                    <p>{trls[TrlKeys.viewLimitLast]}</p>
                </>
            );
        }

        return <Attention kind={AttentionKind.Bad}>{errorMessage}</Attention>;
    }, [
        applicantSignUpUrl,
        employerSignUpUrl,
        errorCode,
        useApplicantSignInUrl,
        isMobile,
        userSignInUrl,
        staticHost,
        trls,
        userType,
        viewLimit,
    ]);

    return (
        <Column xs="4" s="8" m="12" l="16">
            {renderErrorMessage()}
        </Column>
    );
};

export default translation(ResumeError);
