import { WorkSchedule, Employment } from '@hh.ru/types-hh-microcore';

import { JobSearchStatus } from 'src/models/applicantUserStatuses';
import { CurrencyType } from 'src/models/currencies.types';
import { Platform } from 'src/models/locale.types';
import { EmployerStateExtName } from 'src/models/negotiationTopic.types';

import { ResumeAccessType } from 'src/models/resume/resumeAccess.types';
import * as ResumeTypes from 'src/models/resume/resumeCommon.types';
import { ResumeCreds } from 'src/models/resume/resumeCreds.types';
import { VerifiedSkills } from 'src/models/resume/resumeVerifiedSkills.types';

export interface ResumeBaseFields {
    additionalEducation: ResumeTypes.AdditionalEducation;
    advancedKeySkills: ResumeTypes.KeySkill;
    age: number;
    area: ResumeTypes.Area;
    attestationEducation: ResumeTypes.AdditionalEducation;
    birthday: string;
    businessTripReadiness: ResumeTypes.TripsReadiness;
    certificates: ResumeTypes.Certificates;
    citizenship: ResumeTypes.Area;
    driverLicenseTypes: string;
    educationLevel: ResumeTypes.EducationLevel;
    elementaryEducation: ResumeTypes.AdditionalEducation;
    email: string;
    employment: Employment;
    experience: ResumeTypes.Experience;
    firstName: string;
    gender: ResumeTypes.Gender;
    hasPhoto: boolean;
    hasVehicle: boolean;
    keySkills: string;
    lang: ResumeTypes.LangsList;
    language: ResumeTypes.Language;
    lastName: string;
    metro: ResumeTypes.Metro;
    middleName: string;
    personalSite: ResumeTypes.PersonalSite;
    phone: ResumeTypes.Phone;
    photo: number;
    photoUrls: ResumeTypes.PhotoUrl;
    portfolio: number;
    portfolioUrls: ResumeTypes.PhotoUrl;
    preferredContact: ResumeTypes.PreferredContact;
    primaryEducation: ResumeTypes.Education;
    proftest: ResumeTypes.Proftest;
    relocation: ResumeTypes.Relocation;
    relocationArea: ResumeTypes.Area;
    relocationAreaExtended: ResumeTypes.RelocationArea[];
    residenceDistrict: ResumeTypes.District;
    salary: ResumeTypes.Salary;
    skills: string;
    professionalRole: ResumeTypes.ProfessionalRole;
    title: string;
    totalExperience: ResumeTypes.Interval;
    travelTime: ResumeTypes.TravelTime;
    workSchedule: WorkSchedule;
    workTicket: ResumeTypes.Area;
    shortExperience?: ResumeTypes.ShortExperience;
    tags: string[];
    // eslint-disable-next-line camelcase
    update_timeout: number;
}

type ResumeFieldListValue =
    | 'additionalEducation'
    | 'advancedKeySkills'
    | 'professionalRole'
    | 'attestationEducation'
    | 'driverLicenseTypes'
    | 'elementaryEducation'
    | 'experience'
    | 'language'
    | 'phone'
    | 'photo'
    | 'photoUrls'
    | 'portfolioUrls'
    | 'primaryEducation'
    | 'relocationArea'
    | 'workTicket';

type ResumeFieldCustomValue =
    | 'specialization'
    | 'workSchedule'
    | 'portfolio'
    | 'photo'
    | 'employment'
    | 'keySkills'
    | 'lang'
    | 'resumeApplicantSkills';
type ResumeFieldForWrap = Omit<ResumeBaseFields, ResumeFieldListValue | ResumeFieldCustomValue>;

type ResumeWrappedField = {
    [Property in keyof ResumeFieldForWrap]: ResumeField<ResumeFieldForWrap[Property]>;
};

type ResumeFieldListValueForWrap = Pick<ResumeBaseFields, ResumeFieldListValue>;

type ResumeWrappedFieldList = {
    [Property in keyof ResumeFieldListValueForWrap]: ResumeField<ResumeFieldListValueForWrap[Property][]>;
};

export enum ResumeValidationSchema {
    NoValidation = 'no_validation',
    Incomplete = 'incomplete',
    FullValistation = 'full_validation',
}

export interface ResumeAttributes {
    id: string;
    hash: string;
    userId: string;
    hhid: string;
    updated: number;
    lastEditTime: number;
    vacancySearchLastUsageDate: number;
    canPublishOrUpdate: boolean;
    created: number;
    hasConditions: boolean;
    hasErrors: boolean;
    hasPublicVisibility: boolean;
    marked: boolean;
    markServiceExpireTime: string;
    percent: number;
    renewal: boolean;
    renewalServiceExpireTime: string;
    moderated: string;
    nextPublishAt: number;
    status: ResumeTypes.Statuses;
    lang: ResumeTypes.LangsList;
    isSearchable: boolean;
    sitePlatform: Platform;
    // eslint-disable-next-line camelcase
    validation_schema: ResumeValidationSchema | '';
    permission: ResumeTypes.Permission;
    tags: string[];
    // eslint-disable-next-line camelcase
    update_timeout: number;
}

export type ResumeId = string;

export interface LastTopicInfo {
    state: string;
    update: string;
    chatId: number;
    conversationMessagesCount: number;
    conversationUnreadByEmployerCount: number;
    lastEmployerStateExtName: EmployerStateExtName;
    topicId: string;
    vacancyId: string;
}

export interface ResumeSalary {
    amount: number;
    currency: CurrencyType;
}

export type Resume = {
    _attributes: {
        id: ResumeId;
        user: number;
        permission: string;
        hash: string;
        // eslint-disable-next-line camelcase
        sim_hash: string;
    };
    vacancySkills?: {
        arr?: { id: string | number }[];
    };
    applicantUserStatuses: Record<
        string,
        { jobSearchStatus: { name: JobSearchStatus; lastChangeTime: string } | null }
    >;
    area?: { string: string; title?: string }[];
    accessType: ResumeAccessType | null;
    blacklistedByEmployer: boolean;
    confirmableKeySkills: { keySkillProviders: unknown; providers: string[] };
    created: number;
    employment: ResumeField<{ string: Employment }[]>;
    emptyBlocks: ResumeFieldBlock[];
    error: boolean;
    fieldStatuses: { redFields: string[]; greenFields: string[]; leftToFillFields: string[] };
    fio: string;
    firstName: { string: string }[];
    folders: string[];
    hash: string;
    hashFromURL: string;
    hiddenFields: ResumeTypes.HiddenFields[];
    humanDatesRules: { [x: string]: { translation: string } };
    id: string;
    isGift: boolean;
    keySkillsConfirmedByProviders: ResumeField<{
        skillIdsToProviders: {
            [x: string]: {
                skillId: number;
                skillName: string;
                providers: ResumeTypes.Provider[];
            };
        };
        promotedProviders: ResumeTypes.Provider[];
        otherProviders: ResumeTypes.Provider[];
    }>;
    lang: ResumeTypes.LangsList;
    lastActivityTime: string;
    lastTopicState?: LastTopicInfo;
    lastName: { string: string }[];
    moderationNotes: ResumeField;
    openedContacts: boolean;
    percent: number;
    permission: ResumeTypes.Permission;
    phonePreview: ResumeTypes.Phone[];
    portfolio: ResumeField<{ string: number }[]>;
    recommendation: ResumeField<ResumeTypes.Recommendation[]>;
    renewal: boolean;
    resumeContactsOpening: {
        canOpenContactsThroughCIV: boolean;
    };
    salary?: ResumeSalary;
    videos?: string[];
    showUpdateBlock: boolean;
    simHash?: string;
    sitePlatform?: string;
    skills: ResumeField<string>;
    source: string;
    specialization: ResumeField<{ string: number }[]>;
    status: ResumeTypes.Statuses;
    title?: { string: string }[];
    topicId?: string;
    toUpdate: {
        value: number;
        count?: number;
        translation?: string;
        ending?: number;
    };
    updated: number;
    updatedHuman: string;
    updateTimeout: number;
    user: string;
    viewLimit?: string;
    viewResumeContacts: boolean;
    wantWork: boolean;
    wasUpdated: { trl: string; value: number };
    workSchedule: ResumeField<{ string: WorkSchedule }[]>;
    resumeApplicantSkills: VerifiedSkills;
    verifiedSkills: VerifiedSkills;
    creds?: ResumeCreds;
    autoHideTime?: ResumeTypes.AutoHideTime;
    hasPfpTopics?: boolean;
    keySkills: ResumeField<{ string: string }>;
    canOpenContacts?: boolean;
} & ResumeWrappedField &
    ResumeWrappedFieldList;

export type ResumeFieldInvariant = keyof Resume;

export interface ResumeField<I = unknown> {
    value: I | null;
    type: 'field';
    block: ResumeFieldBlock;
    _attributes: {
        status?: ResumeFieldStatus;
    };
}

export enum ResumeFieldBlock {
    Education = 'education',
    Experience = 'experience',
    Personal = 'personal',
    Additional = 'additional',
    Contacts = 'contacts',
    Position = 'position',
    Photo = 'photo',
}

export enum ResumeFieldStatus {
    Ok = 'ok',
    Green = 'green',
    Red = 'red',
    Inactive = 'inactive',
}
