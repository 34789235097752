import { ComponentType, Fragment } from 'react';
import classnames from 'classnames';

import { Link } from '@hh.ru/redux-spa-middleware';
import Text, { TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useProfile } from 'src/hooks/useProfile';
import { useSelector } from 'src/hooks/useSelector';
import { Permission } from 'src/models/resume/resumeCommon.types';
import { PROFILE_SIMPLE_FIELDS } from 'src/utils/constants/profile';

const TrlKeys = {
    edit: 'rb.edit',
};

interface ResumeEditLinkProps {
    /** Название блока резюме */
    block: string;
    /** Название поля в блоке резюме */
    field?: string;
    /** Якорь на конкретное поле резюме */
    anchor?: string;
    /** Текст ссылки. По умолчанию используется перевод "rb.edit" */
    text?: string;
    /** Кастомный css-класс */
    className?: string;
    /** Писать ли текст с большой буквы */
    capitalize?: boolean;
    WrapperComponent?: ComponentType;
}

const ResumeEditLink: TranslatedComponent<ResumeEditLinkProps> = ({
    block,
    field,
    anchor,
    text,
    className,
    capitalize = true,
    trls,
    WrapperComponent = Fragment,
    ...otherProps
}) => {
    const hash = useSelector((state) => state.resume?.hash);
    const canEdit = useSelector((state) => state.resume?.permission === Permission.Edit);
    const isProfile = useProfile(PROFILE_SIMPLE_FIELDS.includes(field));

    if (!canEdit || !hash) {
        return null;
    }

    const fieldParam = field ? `&field=${field}` : '';
    const urlAnchor = anchor ? `#${anchor}` : '';

    let to = `/applicant/resumes/edit/${block}?resume=${hash}${fieldParam}${urlAnchor}`;

    if (isProfile) {
        to = '/profile/me';
    }

    return (
        <WrapperComponent>
            <Link
                {...otherProps}
                className={
                    className ||
                    classnames('resume-block-edit', {
                        'resume-block-edit_capitalize': capitalize,
                    })
                }
                to={to}
            >
                <Text Element="span" size={TextSize.Small}>
                    {text || trls[TrlKeys.edit]}
                </Text>
            </Link>
        </WrapperComponent>
    );
};
export default translation(ResumeEditLink);
