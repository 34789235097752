import { FC, PropsWithChildren } from 'react';

import InfoBlockSubstrate, { InfoBlockSubstrateKind } from 'src/components/InfoBlockSubstrate';
import { useSelector } from 'src/hooks/useSelector';

import AdditionalCheckSnippet from 'src/components/ResumeAdditionalCheck/AdditionalCheckSnippet';

const AdditionalCheckResumeInfo: FC<PropsWithChildren> = () => {
    const isShowSnippetResume = useSelector(({ snippetAccessResume }) => snippetAccessResume?.isShowSnippetResume);
    const additionalCheck = useSelector(({ additionalCheck }) => additionalCheck);

    if (!isShowSnippetResume || !additionalCheck) {
        return null;
    }

    return (
        <InfoBlockSubstrate kind={InfoBlockSubstrateKind.Warning} data-qa="additional-check-resume-info">
            <AdditionalCheckSnippet />
        </InfoBlockSubstrate>
    );
};

export default AdditionalCheckResumeInfo;
