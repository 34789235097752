import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Column from 'bloko/blocks/column';
import { H3 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';

import translation from 'src/components/translation';
import { TransitionType } from 'src/models/employerNegotiations/negotiationLinks';
import { VIEW } from 'src/utils/constants/resumePermission';

import Shl from 'src/pages/ResumeView/components/View/ResumeSidebar/Tests/TestShl';

function Wrapper({ assessmentsData, trls, showEmpty }) {
    if (assessmentsData?.count === 0 && !showEmpty) {
        return null;
    }
    return (
        <div className="resume-sidebar-section-wrapper">
            <Column xs="0" s="4" m="3" l="4">
                <div className="resume-sidebar-section">
                    <H3>{trls[Wrapper.trls.title]}</H3>
                    <VSpacing base={4} />
                    <Shl />
                </div>
            </Column>
        </div>
    );
}

Wrapper.propTypes = {
    assessmentsData: PropTypes.shape({
        count: PropTypes.number,
        assessments: PropTypes.arrayOf(
            PropTypes.shape({
                code: PropTypes.string,
                state: PropTypes.string,
                creationTime: PropTypes.string,
                assessmentId: PropTypes.number,
            })
        ),
    }),
    showEmpty: PropTypes.bool,
    trls: PropTypes.object,
};

Wrapper.trls = {
    title: 'resume.assessments',
};

export default connect((state) => ({
    assessmentsData: state.assessments,
    showEmpty: !!(
        state.resume?.permission === VIEW &&
        state.resume?.hash &&
        state.employerNegotiationLinks[state.resume?.hash]?.[TransitionType.InviteWithAssessements]
    ),
}))(translation(Wrapper));
