import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Text, { TextImportance } from 'bloko/blocks/text';

import ConversionDateInterval from 'src/components/Conversion/DateInterval';
import MonthName from 'src/components/HumanDate/MonthName';
import translation from 'src/components/translation';

const splitDate = (date) => {
    if (!date) {
        return null;
    }
    const splittedDate = date.split('-');
    return {
        year: Number(splittedDate[0]),
        month: Number(splittedDate[1]),
    };
};

const ResumeExperienceInterval = ({ startDate, endDate, interval, trls }) => {
    const start = splitDate(startDate);
    const end = splitDate(endDate);

    return (
        <Fragment>
            {start && (
                <Fragment>
                    <MonthName month={start.month} />
                    &nbsp;
                    {start.year}
                    {' — '}
                </Fragment>
            )}
            {end ? (
                <Fragment>
                    <span className="resume-block__experience-mount-last">
                        <MonthName month={end.month} />
                    </span>
                    &nbsp;
                    {end.year}
                </Fragment>
            ) : (
                trls[ResumeExperienceInterval.trls.tillToday]
            )}
            {interval && (
                <Text importance={TextImportance.Tertiary}>
                    <ConversionDateInterval years={interval.years} months={interval.months} />
                </Text>
            )}
        </Fragment>
    );
};

ResumeExperienceInterval.propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    interval: PropTypes.shape({
        years: PropTypes.number,
        months: PropTypes.number,
    }),
    trls: PropTypes.object,
};

ResumeExperienceInterval.trls = {
    tillToday: 'resume.tillToday',
};

export default translation(ResumeExperienceInterval);
