import { memo } from 'react';

import Text, { TextImportance } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    isHidden: 'resume.hiddenFields.photo',
};

const HiddenPhoto: TranslatedComponent = ({ trls }) => (
    <div className="resume-media resume-media_empty" data-qa="resume-photo">
        <div className="resume-hidden-field resume-hidden-field_photo">
            <Text Element="span" importance={TextImportance.Tertiary}>
                {trls[TrlKeys.isHidden]}
            </Text>
        </div>
    </div>
);

export default memo(translation(HiddenPhoto));
