import { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind, ButtonType, ButtonAppearance } from 'bloko/blocks/button';

import Form from 'src/components/Form';
import translation from 'src/components/translation';
import useFingerprints from 'src/hooks/useFingerprints';
import { NO_ONE } from 'src/models/resume/resumeAccess.types';
import { CORRECTED, BLOCKED, NOT_FINISHED } from 'src/utils/constants/resumeStatuses';

const ResumeSidebarForm = ({ resume, applicantInfo, trls }) => {
    const { hash, status, source, accessType, moderationNotes, fieldStatuses } = resume;

    const fingerprints = useFingerprints('applicant');
    const [fingerprintIteration2, setFingerprintIteration2] = useState(fingerprints.fingerprintIteration2);
    const [fingerprintSp, setFingerprintSp] = useState(fingerprints.fingerprintSp);
    const submitForm = () => {
        setFingerprintIteration2(fingerprints.fingerprintIteration2);
        setFingerprintSp(fingerprints.fingerprintSp);
    };

    if (status === BLOCKED) {
        if (moderationNotes.some((note) => note.significant) || fieldStatuses.redFields.length > 0) {
            return (
                <div className="resume-sidebar-form">
                    <Button
                        Element={Link}
                        to={`/applicant/resumes/completion?resume=${hash}`}
                        kind={ButtonKind.Warning}
                        appearance={ButtonAppearance.Outlined}
                        stretched
                    >
                        {trls[ResumeSidebarForm.trls.correct]}
                    </Button>
                </div>
            );
        }
        return (
            <div className="resume-sidebar-form">
                <Form action="/applicant/resumes/touch" method="POST" onSubmit={submitForm}>
                    <input type="hidden" name="resume" value={hash} />
                    <input type="hidden" name="publish" value="blocked" />
                    <Button type={ButtonType.Submit} kind={ButtonKind.Primary} stretched>
                        {trls[ResumeSidebarForm.trls.moderate]}
                    </Button>
                </Form>
            </div>
        );
    }

    if (status === CORRECTED) {
        return <div className="resume-sidebar-form">{trls[ResumeSidebarForm.trls.waitModeration]}</div>;
    }

    if (status === NOT_FINISHED) {
        return (
            <div className="resume-sidebar-form">
                <Form action="/applicant/resumes/touch" method="POST" onSubmit={submitForm}>
                    <input type="hidden" name="resume" value={hash} />
                    <input type="hidden" name="fingerprintIteration2" value={fingerprintIteration2} />
                    <input type="hidden" name="fingerprintSp" value={fingerprintSp} />
                    <Fragment>
                        <input type="hidden" name="publish" value={!applicantInfo.finished ? 'first' : 'next'} />
                        {source === 'short' && <input type="hidden" name="publish-short" value="full" />}
                        {accessType !== NO_ONE && <input type="hidden" name="createVisibleResume" value="true" />}
                        <Button
                            type={ButtonType.Submit}
                            kind={ButtonKind.Primary}
                            stretched
                            disabled={fieldStatuses.redFields.length > 0}
                        >
                            {trls[ResumeSidebarForm.trls.publish]}
                        </Button>
                    </Fragment>
                </Form>
            </div>
        );
    }

    return null;
};

ResumeSidebarForm.propTypes = {
    resume: PropTypes.object,
    applicantInfo: PropTypes.object.isRequired,
    trls: PropTypes.object,
};

ResumeSidebarForm.trls = {
    waitModeration: 'rb.wait_moderation',
    moderate: 'rb.moderate',
    publish: 'rb.publish',
    correct: 'rb.correct',
};

export default connect((state) => ({
    resume: state.resume,
    applicantInfo: state.applicantInfo,
}))(translation(ResumeSidebarForm));
