import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Link } from '@hh.ru/redux-spa-middleware';
import Column, { ColumnsRow } from 'bloko/blocks/column';
import BlokoLink from 'bloko/blocks/link';

import { selectorMColumnSize } from 'src/components/HiringManager/utils/selectorMColumnSize';
import translation from 'src/components/translation';

import ResumeBlock from 'src/pages/ResumeView/components/View/ResumeBlock';
import ResumeEditLink from 'src/pages/ResumeView/components/View/ResumeEditLink';
import ResumeEducationBody from 'src/pages/ResumeView/components/View/ResumeEducationBody';

class ResumeEducationAttestation extends PureComponent {
    renderProftest = () => {
        const { proftest, trls, mColumnSize } = this.props;
        const { mMain } = mColumnSize;
        if (!proftest.visible || !proftest.attached) {
            return null;
        }
        return (
            <ColumnsRow>
                <Column xs="4" s="8" m={mMain} l="12">
                    {trls[ResumeEducationAttestation.trls.proftestTitle]}{' '}
                    <BlokoLink disableVisited Element={Link} to={proftest.link} target="_blank">
                        {trls[ResumeEducationAttestation.trls.viewProftestResult]}
                    </BlokoLink>
                </Column>
            </ColumnsRow>
        );
    };

    render() {
        const { education, proftest, trls } = this.props;
        if (!education.length && (!proftest.visible || !proftest.attached)) {
            return null;
        }
        return (
            <ResumeBlock
                title={trls[ResumeEducationAttestation.trls.title]}
                data-qa="resume-block-attestation-education"
                EditLink={
                    <ResumeEditLink
                        block="education"
                        field="attestationEducation"
                        data-qa="resume-block-attestation-education-edit"
                    />
                }
            >
                {education.map((education) => {
                    return <ResumeEducationBody key={education.id} {...education} />;
                })}
                {this.renderProftest()}
            </ResumeBlock>
        );
    }
}

ResumeEducationAttestation.propTypes = {
    education: PropTypes.array.isRequired,
    proftest: PropTypes.shape({
        attached: PropTypes.bool,
        resultsReceived: PropTypes.bool,
        visible: PropTypes.bool,
        link: PropTypes.string,
    }).isRequired,
    trls: PropTypes.object,
    mColumnSize: PropTypes.object.isRequired,
};

ResumeEducationAttestation.trls = {
    title: 'rb.title.attestationEducation',
    proftestTitle: 'applicant.resumes.proftestTitle',
    viewProftestResult: 'applicant.resumes.viewProftestResult',
};

export default connect((state) => ({
    education: state.resume?.attestationEducation.value,
    proftest: state.resume?.proftest.value,
    mColumnSize: selectorMColumnSize(state),
}))(translation(ResumeEducationAttestation));
