import { Link } from '@hh.ru/redux-spa-middleware';
import { H2Section } from 'bloko/blocks/header';
import Text, { TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ResumeFieldBlock } from 'src/models/resume/resume.types';
import { Permission } from 'src/models/resume/resumeCommon.types';

const TrlKeys = {
    add: 'rb.empty.add',
    resumeField: {
        [ResumeFieldBlock.Position]: 'rb.title.position',
        [ResumeFieldBlock.Experience]: 'rb.title.experience',
        [ResumeFieldBlock.Personal]: 'rb.title.personal',
        [ResumeFieldBlock.Contacts]: 'rb.title.contacts',
        [ResumeFieldBlock.Photo]: 'rb.title.photo',
        [ResumeFieldBlock.Education]: 'rb.title.education',
        [ResumeFieldBlock.Additional]: 'rb.title.additional',
    },
};

interface ResumeEmptyBlockProps {
    block: ResumeFieldBlock;
}

const ResumeEmptyBlock: TranslatedComponent<ResumeEmptyBlockProps> = ({ block, trls }) => {
    const hash = useSelector((state) => state.resume?.hash);
    const canEdit = useSelector((state) => state.resume?.permission === Permission.Edit);

    if (!canEdit || !hash) {
        return null;
    }

    return (
        <Link
            className={`resume__emptyblock resume__emptyblock_${block}`}
            // tempexp_12820_next-line
            data-gh-resume-empty={block}
            to={`/applicant/resumes/edit/${block}?resume=${hash}`}
            data-qa={`emptyblock-${block}`}
        >
            <Text size={TextSize.Large}>
                <H2Section Element="span" lite>{`${trls[TrlKeys.resumeField[block]]} `}</H2Section>
                <span className="resume__editlink">{trls[TrlKeys.add]}</span>
            </Text>
        </Link>
    );
};

export default translation(ResumeEmptyBlock);
