import { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import Button, { ButtonKind } from 'bloko/blocks/button';
import Checkbox from 'bloko/blocks/checkbox';
import Column from 'bloko/blocks/column';
import FormItem from 'bloko/blocks/form/FormItem';

import translation from 'src/components/translation';
import { changeView } from 'src/models/printVersion';
import { UserType } from 'src/models/userType';
import { VIEW } from 'src/utils/constants/resumePermission';

const ORDER = ['comments', 'followLetter', 'history', 'industries'];

function PrintSettings({ printVersion, trls, letterEmpty, dispatch, resumeId, applicantId, permission, userType }) {
    const print = useCallback(() => {
        window.setTimeout(() => {
            window.print();
        }, 100);

        if (userType === UserType.Employer) {
            Analytics.sendHHEvent('resumePrint', {
                resumeId,
                canViewContacts: permission === VIEW,
                applicantUserId: applicantId,
            });
        }
    }, [userType, resumeId, permission, applicantId]);

    if (Object.entries(printVersion).length === 0) {
        return null;
    }

    return (
        <div className="resume-print-settings noscreen">
            <Column s="8" m="12" l="16">
                {userType !== UserType.Applicant &&
                    ORDER.filter((item) => printVersion[item] !== undefined).map((item) => (
                        <FormItem key={item}>
                            <Checkbox
                                wrapperProps={{ 'data-qa': `resume-print-${item}` }}
                                checked={!printVersion[item]}
                                disabled={item === 'followLetter' && letterEmpty}
                                onChange={(e) => {
                                    dispatch(
                                        changeView({
                                            [item]: !e.target.checked,
                                        })
                                    );
                                }}
                            >
                                {trls[PrintSettings.trls[item]]}
                            </Checkbox>
                        </FormItem>
                    ))}
                <Button kind={ButtonKind.Primary} onClick={print} data-qa="resume-print-action">
                    {trls[PrintSettings.trls.print]}
                </Button>
            </Column>
        </div>
    );
}

PrintSettings.propTypes = {
    printVersion: PropTypes.shape({
        comments: PropTypes.bool,
        followLetter: PropTypes.bool,
        history: PropTypes.bool,
    }),
    resumeId: PropTypes.string,
    applicantId: PropTypes.string,
    permission: PropTypes.string,
    letterEmpty: PropTypes.bool,
    dispatch: PropTypes.func,
    userType: PropTypes.string,
    trls: PropTypes.object,
};

PrintSettings.trls = {
    print: 'resume.print',
    comments: 'resume.comments.noPrint',
    followLetter: 'resume.letter.noPrint',
    history: 'resume.negotiations.noPrint',
    industries: 'resume.industries.noPrint',
};

export default connect(({ printVersion, resume: { id, permission, hash, user }, employerNegotiations, userType }) => ({
    letterEmpty: !(hash && employerNegotiations.responseLetters?.[hash]),
    resumeId: id,
    applicantId: user,
    printVersion,
    permission,
    userType,
}))(translation(PrintSettings));
