import { ComponentProps } from 'react';
import { DefaultRootState } from 'react-redux';

import Column from 'bloko/blocks/column';

import { UserType } from 'src/models/userType';

type MSizes = ComponentProps<typeof Column>['m'];

export const selectorMColumnSize = (state: DefaultRootState): { mMain: MSizes; mSidebar: MSizes } => {
    return state.userType === UserType.Employer && state.isHiringManager
        ? { mMain: '8', mSidebar: '4' }
        : {
              mMain: '9',
              mSidebar: '3',
          };
};
