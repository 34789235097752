import {
    contactInformationViewErrorMessage,
    contactInformationViewInfoMessage,
    contactInformationViewQuotaExceeded,
    contactInformationViewResumeContentIsTooLarge,
    contactInformationViewResumeCountryMismatch,
} from 'src/components/Notifications/ResumeView';

const mapErrorKeyToNotification = {
    quotaExceeded: contactInformationViewQuotaExceeded,
    resumeCountryMismatch: contactInformationViewResumeCountryMismatch,
    resumeContentIsTooLarge: contactInformationViewResumeContentIsTooLarge,
    noAvailableService: contactInformationViewInfoMessage,
    resourceUnavailable: contactInformationViewErrorMessage,
    invalidRequestBody: contactInformationViewErrorMessage,
    resumeNotFound: contactInformationViewErrorMessage,
};

export default mapErrorKeyToNotification;
