import { useCallback, useMemo } from 'react';

import resumeVideoLinkButtonClick, {
    HhtmSource,
} from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_video_link_button_click';
import resumeVideoLinkElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_video_link_element_shown';
import { Link } from '@hh.ru/redux-spa-middleware';
import HSpacing from 'bloko/blocks/hSpacing';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import css from './styles.less';

const TrlKeys = {
    videoWithIcon: 'resume.video.withIcon',
    videoWithoutIcon: 'resume,video.withoutIcon',
};

interface ResumeVideoLinkProps {
    videoLink: string;
    resumeId: number;
    size?: TextSize;
}

const ResumeVideoLink: TranslatedComponent<ResumeVideoLinkProps> = ({
    trls,
    videoLink,
    resumeId,
    size = TextSize.Large,
}) => {
    const hhtmSource = useSelector((state) => state.analyticsParams.hhtmSource);

    const analyticsData = useMemo(() => ({ hhtmSource: hhtmSource as HhtmSource, resumeId }), [hhtmSource, resumeId]);

    const sendVideoClickAnalytic = useCallback(() => {
        resumeVideoLinkButtonClick(analyticsData);
    }, [analyticsData]);

    return (
        <ElementShownAnchor fn={resumeVideoLinkElementShown} {...analyticsData}>
            <Link target="_blank" to={videoLink} onClick={sendVideoClickAnalytic}>
                <div className={css.containerWithIcon}>
                    <div className={css.playIcon} />

                    <HSpacing base={2} />
                    <Text Element="span" importance={TextImportance.Primary} size={size}>
                        {trls[TrlKeys.videoWithIcon]}
                    </Text>
                </div>
            </Link>
        </ElementShownAnchor>
    );
};

export default translation(ResumeVideoLink);
