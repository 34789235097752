import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Column from 'bloko/blocks/column';
import { H3 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';

import translation from 'src/components/translation';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';
import { EDIT } from 'src/utils/constants/resumePermission';
import { BLOCKED, CORRECTED, resumeStatusType } from 'src/utils/constants/resumeStatuses';

import ResumeContactsVisibilityHistoryLink from 'src/pages/ResumeView/components/View/ResumeSidebar/ResumeContactsVisibilityHistory';
import ResumeProgress from 'src/pages/ResumeView/components/View/ResumeSidebar/ResumeProgress';
import ResumeSidebarForm from 'src/pages/ResumeView/components/View/ResumeSidebar/ResumeSidebarForm';
import ResumeSidebarUpdate from 'src/pages/ResumeView/components/View/ResumeSidebar/ResumeSidebarUpdate';
import ResumeSuitableVacancies from 'src/pages/ResumeView/components/View/ResumeSidebar/ResumeSuitableVacancies';
import ResumeVisibility from 'src/pages/ResumeView/components/View/ResumeSidebar/ResumeVisibility';

const ResumePublish = ({ percent, canEdit, status, trls }) => {
    const resumeCorrected = status === CORRECTED;
    const { mSidebar } = useGetMColumnSize();

    if (!canEdit || percent === 0) {
        return null;
    }

    if (status === BLOCKED) {
        return (
            <div className="resume-sidebar-section-wrapper">
                <Column xs="0" s="0" m={mSidebar} l="4">
                    <div className="resume-sidebar-section resume-sidebar-section_first">
                        <div className="resume-sidebar-header resume-sidebar-header_error">
                            <H3>{trls[ResumePublish.trls.blocked]}</H3>
                            <VSpacing base={4} />
                        </div>
                        <ResumeSidebarForm />
                    </div>
                    <div className="resume-sidebar-section">
                        <ResumeVisibility />
                    </div>
                    <ResumeContactsVisibilityHistoryLink />
                </Column>
            </div>
        );
    }

    return (
        <div className="resume-sidebar-section-wrapper">
            <Column xs="0" s="0" m={mSidebar} l="4">
                {resumeCorrected && (
                    <div className="resume-sidebar-section resume-sidebar-section_first">
                        <H3>{trls[ResumePublish.trls.onModeration]}</H3>
                        <VSpacing base={4} />
                        {trls[ResumePublish.trls.onModerationText]}
                    </div>
                )}
                <div
                    className={classnames('resume-sidebar-section', {
                        'resume-sidebar-section_first': !resumeCorrected,
                    })}
                >
                    <ResumeVisibility />
                </div>
                <ResumeContactsVisibilityHistoryLink />
                <div className="resume-sidebar-section">
                    <H3>{trls[ResumePublish.trls.progress]}</H3>
                    <VSpacing base={4} />
                    <ResumeProgress />
                    <ResumeSidebarForm />
                    <ResumeSuitableVacancies />
                    <ResumeSidebarUpdate />
                </div>
            </Column>
        </div>
    );
};

ResumePublish.propTypes = {
    canEdit: PropTypes.bool.isRequired,
    percent: PropTypes.number.isRequired,
    status: resumeStatusType.isRequired,
    trls: PropTypes.object.isRequired,
};

ResumePublish.trls = {
    progress: 'resume.sidebar.progress',
    blocked: 'resume.sidebar.blocked',
    onModeration: 'resume.sidebar.onModeration',
    onModerationText: 'resumeList.recommendations.text.onModeration',
};

export default connect((state) => ({
    canEdit: state.resume?.permission === EDIT,
    percent: state.resume?.percent,
    status: state.resume?.status,
}))(translation(ResumePublish));
