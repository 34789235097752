import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import resumeBreadcrumbsButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_breadcrumbs_button_click';
import { Link } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import BlokoLink from 'bloko/blocks/link';
import urlParser from 'bloko/common/urlParser';

import { HHTM_SOURCE_VACANCY_RESPONSES } from 'Modules/constants/analytics';
import translation from 'src/components/translation';
import { UserType } from 'src/models/userType';

const BackToVacancyResponses = ({ fromResponsesPage, userType, vacancyId, page, resumeId, collection, trls }) => {
    if (!(userType === UserType.Employer && fromResponsesPage)) {
        return null;
    }

    return (
        <span className="noprint">
            <Column xs="4" s="8" m="12" l="16">
                <Gap bottom>
                    <BlokoLink
                        onClick={() => resumeBreadcrumbsButtonClick({ resumeId, vacancyId })}
                        Element={Link}
                        to={`/employer/vacancyresponses?vacancyId=${vacancyId}&page=${page}&collection=${collection}`}
                    >
                        {trls[BackToVacancyResponses.trls.link]}
                    </BlokoLink>
                </Gap>
            </Column>
        </span>
    );
};

BackToVacancyResponses.trls = {
    link: 'resume.button.toresponses',
};

BackToVacancyResponses.propTypes = {
    fromResponsesPage: PropTypes.bool,
    vacancyId: PropTypes.string,
    page: PropTypes.string,
    userType: PropTypes.string,
    collection: PropTypes.string,
    resumeId: PropTypes.string,
    trls: PropTypes.object,
};

export default connect((state) => {
    const params = urlParser(state.request.url).params;
    return {
        resumeId: state.resume?.id,
        userType: state.userType,
        fromResponsesPage: state.analyticsParams.hhtmFrom === HHTM_SOURCE_VACANCY_RESPONSES,
        vacancyId: params.vacancyId && params.vacancyId[0],
        page: params.page && params.page[0],
        collection: params.collection && params.collection[0],
    };
})(translation(BackToVacancyResponses));
