import { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Column from 'bloko/blocks/column';
import { H3 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';

import translation from 'src/components/translation';
import { EDIT } from 'src/utils/constants/resumePermission';
import { BLOCKED, CORRECTED, resumeStatusType } from 'src/utils/constants/resumeStatuses';

import ResumeContactsVisibilityHistoryLink from 'src/pages/ResumeView/components/View/ResumeSidebar/ResumeContactsVisibilityHistory';
import ResumeProgress from 'src/pages/ResumeView/components/View/ResumeSidebar/ResumeProgress';
import ResumeSidebarForm from 'src/pages/ResumeView/components/View/ResumeSidebar/ResumeSidebarForm';
import ResumeSidebarUpdate from 'src/pages/ResumeView/components/View/ResumeSidebar/ResumeSidebarUpdate';
import ResumeSuitableVacancies from 'src/pages/ResumeView/components/View/ResumeSidebar/ResumeSuitableVacancies';
import ResumeVisibility from 'src/pages/ResumeView/components/View/ResumeSidebar/ResumeVisibility';

const ResumePublishSmall = ({ percent, canEdit, status, trls }) => {
    if (!canEdit || percent === 0) {
        return null;
    }

    if (status === BLOCKED) {
        return (
            <Fragment>
                <Column xs="4" s="4">
                    <div className="resume-sidebar-section resume-sidebar-section_left">
                        <div className="resume-sidebar-header resume-sidebar-header_error">
                            <H3>{trls[ResumePublishSmall.trls.blocked]}</H3>
                            <VSpacing base={4} />
                        </div>
                        <ResumeSidebarForm />
                    </div>
                </Column>
                <Column xs="4" s="4">
                    <div className="resume-sidebar-section resume-sidebar-section_right">
                        <ResumeVisibility />
                    </div>
                    <ResumeContactsVisibilityHistoryLink modifier="right" />
                </Column>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <Column xs="4" s="4">
                {status === CORRECTED && (
                    <div className="resume-sidebar-section resume-sidebar-section_left">
                        <H3>{trls[ResumePublishSmall.trls.onModeration]}</H3>
                        <VSpacing base={4} />
                        {trls[ResumePublishSmall.trls.onModerationText]}
                    </div>
                )}
                <div className="resume-sidebar-section resume-sidebar-section_left">
                    <ResumeVisibility />
                    <ResumeSidebarUpdate />
                    <ResumeSidebarForm />
                </div>
                <ResumeContactsVisibilityHistoryLink modifier="left" />
            </Column>
            <Column xs="4" s="4">
                <div className="resume-sidebar-section resume-sidebar-section_right">
                    <H3>{trls[ResumePublishSmall.trls.progress]}</H3>
                    <VSpacing base={4} />
                    <ResumeProgress />
                    <ResumeSuitableVacancies />
                </div>
            </Column>
        </Fragment>
    );
};

ResumePublishSmall.propTypes = {
    canEdit: PropTypes.bool.isRequired,
    percent: PropTypes.number.isRequired,
    status: resumeStatusType.isRequired,
    trls: PropTypes.object.isRequired,
};

ResumePublishSmall.trls = {
    progress: 'resume.sidebar.progress',
    blocked: 'resume.sidebar.blocked',
    onModeration: 'resume.sidebar.onModeration',
    onModerationText: 'resumeList.recommendations.text.onModeration',
};

export default connect((state) => ({
    canEdit: state.resume?.permission === EDIT,
    percent: state.resume?.percent,
    status: state.resume?.status,
}))(translation(ResumePublishSmall));
