import { memo, FC } from 'react';

import VSpacing from 'bloko/blocks/vSpacing';

import { useSelector } from 'src/hooks/useSelector';
import { ResumeFieldBlock } from 'src/models/resume/resume.types';
import { HiddenFields, Permission } from 'src/models/resume/resumeCommon.types';
import ResumeEmptyBlock from 'src/pages/ResumeView/components/View/ResumeEmptyBlock';

import AddPhoto from 'src/pages/ResumeView/components/ResumeHeader/ResumeMedia/AddPhoto';
import ForbiddenPhoto from 'src/pages/ResumeView/components/ResumeHeader/ResumeMedia/ForbiddenPhoto';
import HiddenPhoto from 'src/pages/ResumeView/components/ResumeHeader/ResumeMedia/HiddenPhoto';
import Photo from 'src/pages/ResumeView/components/ResumeHeader/ResumeMedia/Photo';
import ResumeVideo from 'src/pages/ResumeView/components/ResumeHeader/ResumeMedia/ResumeVideo';

const ResumeMedia: FC = () => {
    const isHidden = useSelector((state) => state.resume?.hiddenFields?.includes(HiddenFields.NamesAndPhoto));
    const percent = useSelector((state) => state.resume?.percent);
    const photoUrls = useSelector((state) => state.resume?.photoUrls?.value);
    const hasPhoto = useSelector((state) => state.resume?.hasPhoto && !!state.resume?.hasPhoto.value);
    const canEdit = useSelector((state) => state.resume?.permission === Permission.Edit);

    const photoIsEmpty = !photoUrls || !photoUrls.length;

    if (percent === 0) {
        return <ResumeEmptyBlock block={ResumeFieldBlock.Photo} />;
    }

    if (!photoIsEmpty && (!isHidden || canEdit)) {
        return (
            <div className="resume-media">
                <Photo photoUrls={photoUrls} />
                <VSpacing base={2} />
                <ResumeVideo />
            </div>
        );
    }

    if (photoIsEmpty && canEdit) {
        return <AddPhoto />;
    }

    if (photoIsEmpty && hasPhoto) {
        return <ForbiddenPhoto />;
    }

    if (isHidden && !canEdit) {
        return <HiddenPhoto />;
    }

    return (
        <div className="resume-media">
            <ResumeVideo />
        </div>
    );
};

export default memo(ResumeMedia);
