import { ComponentProps } from 'react';

import Button, { ButtonAppearance, ButtonIconPosition } from 'bloko/blocks/button';
import { ClickMenu } from 'bloko/blocks/drop';
import { ChevronScaleSmallKindDown } from 'bloko/blocks/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ChangeTopicMenuItem, { SendAnalyticsHandler } from 'src/components/ChangeTopicMenuItem';
import translation from 'src/components/translation';
import { NegotiationLink } from 'src/models/employerNegotiations/negotiationLinks';

const TrlKeys = {
    buttonText: 'vacancyResponse.discard',
};

interface ChangeTopicButtonProps {
    appearance?: ButtonAppearance;
    links: NegotiationLink[];
    host?: ComponentProps<typeof ClickMenu>['host'];
    showIcon?: boolean;
    stretched?: boolean;
    sendAnalytics?: SendAnalyticsHandler;
    dataQa?: string;
}

const ChangeTopicButton: TranslatedComponent<ChangeTopicButtonProps> = ({
    trls,
    links,
    appearance,
    showIcon,
    stretched,
    sendAnalytics,
    dataQa,
}) => (
    <ClickMenu
        render={() =>
            links.map(({ name, url }) => (
                <ChangeTopicMenuItem key={name} name={name} url={url} sendAnalytics={sendAnalytics} />
            ))
        }
    >
        <Button
            appearance={appearance}
            stretched={stretched}
            {...(showIcon && {
                icon: <ChevronScaleSmallKindDown />,
                iconPosition: ButtonIconPosition.Right,
            })}
            data-qa={dataQa}
        >
            {trls[TrlKeys.buttonText]}
        </Button>
    </ClickMenu>
);

export default translation(ChangeTopicButton);
export type { SendAnalyticsHandler };
