import { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { VendorAnalytics } from '@hh.ru/analytics-js';
import Text, { TextImportance } from 'bloko/blocks/text';

import formatDate from 'Modules/formatDate';
import Result from 'src/components/Employer/AssessmentResult';
import translation from 'src/components/translation';
import { TransitionType } from 'src/models/employerNegotiations/negotiationLinks';

function Shl({ trls, assessmentsData, inviteWithAssessmentsUrl, visible }) {
    if (!visible || !assessmentsData || !assessmentsData.assessments) {
        return null;
    }

    let inviteWithAssessmentsJsx = '';
    if (inviteWithAssessmentsUrl) {
        inviteWithAssessmentsJsx = (
            <a
                href={inviteWithAssessmentsUrl}
                onClick={() => VendorAnalytics.eventToStorage('employer', 'invite', 'recommended')}
                data-qa="resume__invite-with-assessment-button"
            >
                {trls[Shl.trls.inviteWithAssessments]}
            </a>
        );
    }

    return (
        <Fragment>
            {inviteWithAssessmentsJsx}
            {assessmentsData.assessments.map((assessment) => (
                <div className="resume-sidebar-item" key={assessment.assessmentId}>
                    <div className="resume-sidebar-item__text-wrapper">{trls[Shl.trls[assessment.code]]}</div>
                    <div className="resume-sidebar-item__info">
                        <Text importance={TextImportance.Tertiary}>
                            <span suppressHydrationWarning>{formatDate(assessment.creationTime, 'dd.MM.yy')}</span>
                        </Text>
                    </div>
                    <div className="resume-sidebar-item__info">
                        <Text importance={TextImportance.Tertiary}>
                            <Result assessment={assessment} />
                        </Text>
                    </div>
                </div>
            ))}
        </Fragment>
    );
}

Shl.defaultProps = {
    visible: true,
};

Shl.propTypes = {
    visible: PropTypes.bool,
    trls: PropTypes.object,
    assessmentsData: PropTypes.shape({
        count: PropTypes.number,
        assessments: PropTypes.arrayOf(
            PropTypes.shape({
                code: PropTypes.string,
                state: PropTypes.string,
                creationTime: PropTypes.string,
                assessmentId: PropTypes.number,
            })
        ),
    }),
    inviteWithAssessmentsUrl: PropTypes.string,
};

Shl.trls = {
    inviteWithAssessments: 'resume.button.inviteToAssessment',

    SHL_NUMERIC: 'assessments.tools.SHL_NUMERIC',
    SHL_OPQ: 'assessments.tools.SHL_OPQ',
    SHL_VERBAL: 'assessments.tools.SHL_VERBAL',
    SKILLAZ_VIDEO_INTERVIEW: 'assessments.tools.SKILLAZ_VIDEO_INTERVIEW',
};

export default connect((state) => ({
    assessmentsData: state.assessments,
    inviteWithAssessmentsUrl:
        state.resume?.hash &&
        state.employerNegotiationLinks[state.resume?.hash]?.[TransitionType.InviteWithAssessements],
}))(translation(Shl));
