// tempexp_HH-23996_file
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';

import { sendHHEventButtonClick, sendHHEventElementShown } from '@hh.ru/analytics-js/lib/hhAnalytics';

import { useSelector } from 'src/hooks/useSelector';

import HrlinkBannerAdv from 'src/components/HrlinkBanner/Adv';
import HrlinkBannerNative from 'src/components/HrlinkBanner/Native';

const HRLINK_BASE_URL = '/article/hrlink';

const ANALYTICS_ELEMENT_NAME = 'hrlink_promo_banner';

interface HrlinkBannerProps {
    type: 'adv' | 'native';
    utmContent: 'resume' | 'responses';
}

const HrlinkBanner: FC<HrlinkBannerProps> = ({ type, utmContent }) => {
    const ref = useRef<HTMLDivElement>(null);

    const employerId = useSelector((state) => state.employerId);
    const managerId = useSelector((state) => state.employerManager?.id);
    const vacancyId = useSelector((state) => state.router.location.query.vacancyId);

    const analyticsParams = useMemo(
        () => ({
            bannerType: type,
            employerId,
            managerId,
            vacancyId,
        }),
        [employerId, managerId, type, vacancyId]
    );

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        sendHHEventElementShown(ref.current, {
            ...analyticsParams,
            name: ANALYTICS_ELEMENT_NAME,
        });
    }, [analyticsParams]);

    const term = type === 'native' ? 'add_kedo' : `${utmContent === 'responses' ? 'sidebar_' : ''}add_service`;
    const url = `${HRLINK_BASE_URL}?utm_source=hh&utm_campaign=portfolio_23996&utm_medium=${type}&utm_content=${utmContent}&utm_term=${term}`;

    const onButtonClick = useCallback(() => {
        sendHHEventButtonClick(ANALYTICS_ELEMENT_NAME, analyticsParams);
    }, [analyticsParams]);

    const Component = type === 'adv' ? HrlinkBannerAdv : HrlinkBannerNative;

    return (
        <div ref={ref}>
            <Component url={url} onButtonClick={onButtonClick} />
        </div>
    );
};

export default HrlinkBanner;
