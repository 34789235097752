import { FC, PropsWithChildren } from 'react';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';

import InfoBlockSubstrate from 'src/components/InfoBlockSubstrate';
import BuyContacts from 'src/components/ResumeContacts/BuyContacts';
import { useSelector } from 'src/hooks/useSelector';

const ResumeWithoutContactInfo: FC<PropsWithChildren> = () => {
    const isShowSnippetResume = useSelector(({ snippetAccessResume }) => snippetAccessResume?.isShowSnippetResume);
    const additionalCheck = useSelector(({ additionalCheck }) => additionalCheck);
    const { hasPfpTopics, canOpenContacts } = useSelectorNonNullable((state) => state.resume);

    if (!isShowSnippetResume || additionalCheck || hasPfpTopics || !canOpenContacts) {
        return null;
    }

    return (
        <InfoBlockSubstrate data-qa="resume-demo-block">
            <BuyContacts />
        </InfoBlockSubstrate>
    );
};

export default ResumeWithoutContactInfo;
