export const PROFILE_SIMPLE_FIELDS = [
    'firstName',
    'lastName',
    'middleName',
    'area',
    'gender',
    'citizenship',
    'workTicket',
    'birthday',
    'metro',
    'relocation',
    'relocationArea',
    'language',
    'driverLicenseTypes',
    'hasVehicle',
];
