import Column from 'bloko/blocks/column';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ApplicantRsvVerificationCard from 'src/components/ApplicantRsvVerification/ApplicantRsvVerificationCard';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';

import ResumeBlock from 'src/pages/ResumeView/components/View/ResumeBlock';

const TrlKeys = {
    title: 'resume.rsv.title',
};
const ResumeCompetencies: TranslatedComponent = ({ trls }) => {
    const resumeHash = useSelector((state) => state.resume?.hashFromURL);
    const { mMain } = useGetMColumnSize();
    const rsvCompetencyList = useSelector((state) => state.rsvCompetencyData?.list);

    if (!rsvCompetencyList?.visible || !resumeHash) {
        return null;
    }

    return (
        <ResumeBlock title={trls[TrlKeys.title]} EditLink={false} noPrint>
            <Column xs="4" s="8" m={mMain} l="12">
                <div className="resume-block-container">
                    <ApplicantRsvVerificationCard resumeHash={resumeHash} />
                </div>
            </Column>
        </ResumeBlock>
    );
};

export default translation(ResumeCompetencies);
