import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Button, { ButtonKind } from 'bloko/blocks/button';
import Gap from 'bloko/blocks/gap';

import translation from 'src/components/translation';

const ResumeUpdateView = ({ disabled, doUpdate, error, message, trls }) => {
    return (
        <Fragment>
            <Gap top>
                {error && (
                    <div className="error-text" data-qa="resume-update-error">
                        {trls[ResumeUpdateView.trls.error]}
                    </div>
                )}
                <div className="resume-sidebar-link">
                    <Button
                        kind={ButtonKind.Primary}
                        stretched
                        disabled={disabled}
                        onClick={doUpdate}
                        data-qa="resume-update-button"
                    >
                        {trls[ResumeUpdateView.trls.touch]}
                    </Button>
                </div>
            </Gap>
            {disabled && message && (
                <div className="resume-sidebar-text" suppressHydrationWarning data-qa="resume-update-message">
                    {message}
                </div>
            )}
        </Fragment>
    );
};

ResumeUpdateView.propTypes = {
    /** Обновление недоступно в данный момент */
    disabled: PropTypes.bool,
    /** Обновить резюме бесплатно без смс */
    doUpdate: PropTypes.func,
    /** Произошла ошибка при обновлении */
    error: PropTypes.bool,
    /** Сообщение о том, почему недоступно обновление */
    message: PropTypes.string,
    /** Переводы */
    trls: PropTypes.object.isRequired,
};

ResumeUpdateView.defaultProps = {
    doUpdate: () => {},
    disabled: false,
    error: false,
    message: '',
};

ResumeUpdateView.trls = {
    error: 'rb.touch.error',
    touch: 'rb.touch',
};

export default translation(ResumeUpdateView);
