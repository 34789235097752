import PropTypes from 'prop-types';

import { Link } from '@hh.ru/redux-spa-middleware';
import Column, { ColumnsRow } from 'bloko/blocks/column';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';

import paths from 'src/app/routePaths';
import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';

const ResumeEducationBody = ({ year, universityId, name, showOrganization, organization, result }) => {
    const { mMain } = useGetMColumnSize();
    const firstColumnMWidth = 2;
    return (
        <div className="resume-block-item-gap">
            <ColumnsRow>
                <Column xs="4" s="2" m={`${firstColumnMWidth}`} l="2">
                    {year}
                </Column>
                <Column xs="4" s="6" m={`${+mMain - firstColumnMWidth}`} l="10">
                    <div className="resume-block-container" data-qa="resume-block-education-item">
                        <Text data-qa="resume-block-education-name" strong>
                            {universityId ? (
                                <BlokoLink
                                    Element={Link}
                                    to={`${paths.resumeSearch}?university=${universityId}`}
                                    kind={LinkKind.Tertiary}
                                >
                                    <AsyncHighlighter>{name}</AsyncHighlighter>
                                </BlokoLink>
                            ) : (
                                <AsyncHighlighter>{name}</AsyncHighlighter>
                            )}
                        </Text>
                        {showOrganization && (
                            <div data-qa="resume-block-education-organization">
                                <AsyncHighlighter>
                                    {organization}
                                    {organization && result && ', '}
                                    {result}
                                </AsyncHighlighter>
                            </div>
                        )}
                    </div>
                </Column>
            </ColumnsRow>
        </div>
    );
};

ResumeEducationBody.propTypes = {
    year: PropTypes.number,
    universityId: PropTypes.number,
    name: PropTypes.string,
    showOrganization: PropTypes.bool,
    organization: PropTypes.string,
    result: PropTypes.string,
};

ResumeEducationBody.defaultProps = {
    showOrganization: true,
};

export default ResumeEducationBody;
