import Button from 'bloko/blocks/button';
import { H3 } from 'bloko/blocks/header';
import Modal, { ModalContent, ModalFooter, ModalHeader, ModalTitle } from 'bloko/blocks/modal';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './index.less';

const TrlKeys = {
    title: 'skillLevels.modal.title',
    levels: {
        0: {
            rank: 1,
            title: 'skillLevels.modal.basic',
            items: { 0: 'skillLevels.modal.basic.0', 1: 'skillLevels.modal.basic.1' },
        },
        1: {
            rank: 2,
            title: 'skillLevels.modal.medium',
            items: { 0: 'skillLevels.modal.medium.0', 1: 'skillLevels.modal.medium.1' },
        },
        2: {
            rank: 3,
            title: 'skillLevels.modal.advanced',
            items: {
                0: 'skillLevels.modal.advanced.0',
                1: 'skillLevels.modal.advanced.1',
                2: 'skillLevels.modal.advanced.2',
            },
        },
    },
    noneTitle: 'skillLevels.modal.none',
    close: 'skillLevels.modal.close',
};

interface SkillLevelModalProps {
    visible: boolean;
    onClose: () => void;
}
const SkillLevelModal: TranslatedComponent<SkillLevelModalProps> = ({ trls, visible, onClose }) => {
    const levels = Object.keys(TrlKeys.levels);
    return (
        <Modal visible={visible} onClose={onClose}>
            <ModalHeader>
                <ModalTitle>{trls[TrlKeys.title]}</ModalTitle>
            </ModalHeader>
            <ModalContent>
                <div className={styles.modalItems}>
                    {levels.map((level) => {
                        const items = Object.values(TrlKeys.levels[level].items);
                        return (
                            <div key={level} className={styles.modalItem}>
                                <H3>
                                    <div className={styles.levelTitle}>{trls[TrlKeys.levels[level].title]}</div>
                                </H3>
                                <VSpacing base={2} />
                                <ul className={styles.modalList}>
                                    {items.map((item) => (
                                        <li key={item}>
                                            <Text Element={'span'} size={TextSize.Medium}>
                                                {trls[item]}
                                            </Text>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        );
                    })}
                </div>
                <VSpacing base={4} />
                <Text size={TextSize.Medium} importance={TextImportance.Secondary}>
                    {trls[TrlKeys.noneTitle]}
                </Text>
            </ModalContent>
            <ModalFooter>
                <Button onClick={onClose}>{trls[TrlKeys.close]}</Button>
            </ModalFooter>
        </Modal>
    );
};

export default translation(SkillLevelModal);
