import { ResumeCreds } from 'src/models/resume/resumeCreds.types';

export const isQuestionRendered = (answers: string[], creds: ResumeCreds): boolean =>
    answers.filter((answerId) => creds.answers[answerId] && !creds.answers[answerId]?.skipAtResult).length > 0;

export const isAnyQuestionRendered = (creds: ResumeCreds): boolean => {
    return (
        Object.values(creds.questionToAnswerMap)
            .map((answers) => isQuestionRendered(answers, creds))
            .filter(Boolean).length > 0
    );
};
