import { useState } from 'react';

import resumePhotoButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_photo_button_click';
import Modal, { ModalHeader, ModalTitle } from 'bloko/blocks/modal';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import CdnImg from 'src/components/CdnImg';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { PhotoUrl } from 'src/models/resume/resumeCommon.types';
import ResumeEditLink from 'src/pages/ResumeView/components/View/ResumeEditLink';

interface PhotoProps {
    photoUrls: PhotoUrl[];
}

const TrlKeys = {
    changePhoto: 'rb.photo.change',
};

const Photo: TranslatedComponent<PhotoProps> = ({ photoUrls, trls }) => {
    const title = useSelector((state) => state.resume?.title?.value || '');
    const resumeId = useSelector((state) => state.resume?.id);
    const vacancyId = useSelector((state) => state.router?.location?.query?.vacancyId);
    const photoBig = photoUrls[0].big;

    const [photoModalVisible, setPhotoModalVisible] = useState(false);

    if (!photoBig) {
        return null;
    }

    return (
        <div data-qa="resume-photo">
            <CdnImg
                loading="lazy"
                path={photoBig}
                alt=""
                className="resume-media__image"
                data-qa="resume-photo-image"
                onClick={() => {
                    resumePhotoButtonClick({ vacancyId, resumeId });
                    setPhotoModalVisible(true);
                }}
            />
            <Modal visible={photoModalVisible} onClose={() => setPhotoModalVisible(false)} data-qa="resume-photo-modal">
                <ModalHeader>
                    <ModalTitle data-qa="resume-photo-modal-title">{title}</ModalTitle>
                </ModalHeader>
                <div className="resume-media-modal-image-wrapper">
                    <CdnImg
                        className="resume-media-modal-image"
                        path={photoBig}
                        alt=""
                        data-qa="resume-photo-modal-image"
                    />
                </div>
            </Modal>
            <p>
                <ResumeEditLink
                    block="photo"
                    data-qa="resume-block-photo-edit"
                    text={trls[TrlKeys.changePhoto]}
                    capitalize={false}
                />
            </p>
        </div>
    );
};

export default translation(Photo);
