import { useRef } from 'react';

import Select, { Option } from 'bloko/blocks/select';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Form from 'src/components/Form';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    RU: 'topbuttons.resume.lang.RU',
    EN: 'topbuttons.resume.lang.EN',
    UA: 'topbuttons.resume.lang.UA',
    AZ: 'topbuttons.resume.lang.AZ',
    KZ: 'topbuttons.resume.lang.KZ',
    UZ: 'topbuttons.resume.lang.UZ',
    KG: 'topbuttons.resume.lang.KG',
};

const ResumeChangeLanguage: TranslatedComponent = ({ trls }) => {
    const formRef = useRef<HTMLFormElement>(null);
    const submitForm = () => formRef.current?.submit?.();
    const languages = useSelector((state) => state.resumeLanguages);
    const currentLanguage = useSelector((state) => state.resume?.lang || '');
    const hash = useSelector((state) => state.resume?.hash);

    return (
        <Form ref={formRef} className="resume-header-actions__lang" method={hash ? 'POST' : 'GET'}>
            <input type="hidden" name="resume" value={hash} />
            <input type="hidden" name="nativeForm" />
            <Select name="lang" flexible defaultValue={currentLanguage} onChange={submitForm}>
                {languages.map((language) => (
                    <Option key={language} value={language}>
                        {trls[TrlKeys[language]]}
                    </Option>
                ))}
            </Select>
        </Form>
    );
};

export default translation(ResumeChangeLanguage);
