import { useCallback } from 'react';
import { Field, useForm } from 'react-final-form';

import { TextArea } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';

const MAX_LENGTH = 9999;

const TrlKeys = {
    required: 'employer.hiringManager.suggest.requiredError',
    maxLength: 'resume.comments.lengthWarning',
};

const TextareaField: TranslatedComponent = ({ trls }) => {
    const { submit } = useForm();
    const validation = useCallback((value: string | undefined, otherValues: object) => {
        const verdict = 'verdict' in otherValues ? otherValues.verdict : null;
        if (!value && !verdict) {
            return 'required';
        }
        if (value && value.length > MAX_LENGTH) {
            return 'maxLength';
        }

        return undefined;
    }, []);

    return (
        <Field<string> name="text" validate={validation}>
            {({ input, meta: { error, touched } }) => (
                <>
                    <TextArea
                        onValidate={() => false}
                        onFocus={input.onFocus}
                        onBlur={input.onBlur}
                        maxLength={MAX_LENGTH}
                        layout="hug"
                        value={input.value}
                        onChange={input.onChange}
                        name={input.name}
                        invalid={touched && !!error}
                        onKeyPress={(e) => {
                            if ((e.key === 'Enter' || e.key === '\n') && e.ctrlKey) {
                                e.preventDefault();
                                void submit?.();
                            }
                        }}
                        errorMessage={
                            error in TrlKeys &&
                            format(trls[TrlKeys[error as keyof typeof TrlKeys]], { '{0}': MAX_LENGTH })
                        }
                    />
                </>
            )}
        </Field>
    );
};

export default translation(TextareaField);
