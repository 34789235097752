import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Column, { ColumnsRow } from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';

import translation from 'src/components/translation';
import { useProfile } from 'src/hooks/useProfile';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';

import ResumeBlock from 'src/pages/ResumeView/components/View/ResumeBlock';
import ResumeEditLink from 'src/pages/ResumeView/components/View/ResumeEditLink';
import ResumeEmptyBlock from 'src/pages/ResumeView/components/View/ResumeEmptyBlock';
import ResumePositionTitle from 'src/pages/ResumeView/components/View/ResumePositionTitle';

const ResumePosition = ({
    title,
    salary,
    professionalRoles,
    employment,
    workSchedule,
    isEmpty,
    trls,
    travelTime,
    businessTripReadiness,
    gender,
}) => {
    const isProfile = useProfile();
    const { mMain } = useGetMColumnSize();

    if (isEmpty) {
        return (
            <ColumnsRow>
                <Column xs="4" s="8" m={mMain} l="12">
                    <ResumeEmptyBlock block="position" />
                </Column>
            </ColumnsRow>
        );
    }

    const content = (
        <>
            <div className="resume-block-container">
                <Gap bottom>
                    <span data-qa="resume-block-specialization-category">
                        {trls[ResumePosition.trls.specializationsTitle]}:
                    </span>
                    <ul>
                        {professionalRoles.map((role) => {
                            return (
                                <li
                                    className="resume-block__specialization"
                                    data-qa="resume-block-position-specialization"
                                    key={role.id}
                                >
                                    {role.trl}
                                </li>
                            );
                        })}
                    </ul>
                </Gap>
                <p>
                    {trls[ResumePosition.trls.employment]}
                    {employment
                        .map((employmentItem) => trls[ResumePosition.trls[`employment.${employmentItem.string}`]])
                        .join(', ')}
                </p>
                <p>
                    {trls[ResumePosition.trls.workSchedule]}
                    {workSchedule
                        .map((workScheduleItem) => trls[ResumePosition.trls[`workSchedule.${workScheduleItem.string}`]])
                        .join(', ')}
                </p>
                {travelTime !== '' && isProfile && (
                    <p>
                        {trls[ResumePosition.trls.travelTime]}
                        {': '}
                        <span className="resume-block-travel-time">
                            {trls[ResumePosition.trls[`traveltime.${travelTime}`]]}
                        </span>
                    </p>
                )}
                {!!businessTripReadiness && isProfile && (
                    <p className="resume-block-business-trip-readiness">
                        {gender ? (
                            <span>{trls[ResumePosition.trls[`businessTrip.${businessTripReadiness}.${gender}`]]}</span>
                        ) : (
                            <span>{trls[ResumePosition.trls[`businessTrip.${businessTripReadiness}.unknown`]]}</span>
                        )}
                    </p>
                )}
            </div>
            {isProfile && <ResumeEditLink block="position" data-qa="resume-block-position-edit" />}
        </>
    );

    return (
        <ResumeBlock
            title={<ResumePositionTitle title={title} salary={salary} />}
            data-qa="resume-block-position"
            profileTitle={isProfile}
        >
            {isProfile ? (
                content
            ) : (
                <Column xs="4" s="8" m={mMain} l="12">
                    {content}
                </Column>
            )}
        </ResumeBlock>
    );
};

ResumePosition.propTypes = {
    title: PropTypes.node,
    professionalRoles: PropTypes.array,
    salary: PropTypes.object,
    employment: PropTypes.array.isRequired,
    workSchedule: PropTypes.array.isRequired,
    travelTime: PropTypes.string.isRequired,
    businessTripReadiness: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    isEmpty: PropTypes.bool,
    trls: PropTypes.object,
    mWidth: PropTypes.string.isRequired,
};

ResumePosition.trls = {
    specializationsTitle: 'resume.professionalRoles.specializations',
    employment: 'resume.employment.title',
    'employment.full': 'employment.lowercase.full',
    'employment.part': 'employment.lowercase.part',
    'employment.project': 'employment.lowercase.project',
    'employment.volunteer': 'employment.lowercase.volunteer',
    'employment.probation': 'employment.lowercase.probation',
    workSchedule: 'resume.workSchedule.title',
    'workSchedule.full_day': 'schedule.lowercase.full_day',
    'workSchedule.fullDay': 'schedule.lowercase.fullDay',
    'workSchedule.fly_in_fly_out': 'schedule.lowercase.fly_in_fly_out',
    'workSchedule.flyInFlyOut': 'schedule.lowercase.flyInFlyOut',
    'workSchedule.FLY_IN_FLY_OUT': 'schedule.lowercase.FLY_IN_FLY_OUT',
    'workSchedule.flexible': 'schedule.lowercase.flexible',
    'workSchedule.shift': 'schedule.lowercase.shift',
    'workSchedule.remote': 'schedule.lowercase.remote',
    travelTime: 'rb.title.travelTime',
    'traveltime.less_than_hour': 'traveltime.less_than_hour',
    'traveltime.any': 'traveltime.any',
    'traveltime.from_hour_to_one_and_half': 'traveltime.from_hour_to_one_and_half',

    'businessTrip.never.male': 'rb.businessTripReadiness.never.male',
    'businessTrip.ready.male': 'rb.businessTripReadiness.ready.male',
    'businessTrip.sometimes.male': 'rb.businessTripReadiness.sometimes.male',
    'businessTrip.never.female': 'rb.businessTripReadiness.never.female',
    'businessTrip.ready.female': 'rb.businessTripReadiness.ready.female',
    'businessTrip.sometimes.female': 'rb.businessTripReadiness.sometimes.female',
    'businessTrip.never.unknown': 'rb.businessTripReadiness.never.unknown',
    'businessTrip.ready.unknown': 'rb.businessTripReadiness.ready.unknown',
    'businessTrip.sometimes.unknown': 'rb.businessTripReadiness.sometimes.unknown',
};

export default connect((state) => ({
    title: state.resume?.title.value,
    salary: state.resume?.salary.value,
    professionalRoles: state.resume?.professionalRole.value,
    employment: state.resume?.employment.value,
    workSchedule: state.resume?.workSchedule.value,
    isEmpty: state.resume?.emptyBlocks.includes('position'),
    travelTime: state.resume?.travelTime.value,
    businessTripReadiness: state.resume?.businessTripReadiness.value,
    gender: state.resume?.gender.value,
}))(translation(ResumePosition));
