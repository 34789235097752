import { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import formatDate from 'Modules/formatDate';
import translation from 'src/components/translation';

const ResumeUpdateDate = ({ date, trls }) =>
    date ? (
        <Fragment>
            {trls[ResumeUpdateDate.trls.updates]}
            &#160;
            <span suppressHydrationWarning>{formatDate(date, 'dd.MM.y HH:mm')}</span>
        </Fragment>
    ) : null;

ResumeUpdateDate.propTypes = {
    date: PropTypes.number,
    trls: PropTypes.object,
};

ResumeUpdateDate.trls = {
    updates: 'employer.resume.updated',
};

export default connect((state) => ({
    date: state.resume?.updated,
}))(translation(ResumeUpdateDate));
