import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { EDIT } from 'src/utils/constants/resumePermission';

const Map = ({ trls }) => {
    const resume = useSelector((state) => state.resume);
    const staticHost = useSelector(({ config: { staticHost } }) => staticHost);
    const searchPointsByResumeHash = useSelector((state) => state.userSearchPointsByResumeHash);
    const hash = useSelector(({ resume }) => resume.hashFromURL);
    const searchPoints = searchPointsByResumeHash[hash];
    const mapContainer = useRef();
    const [visibleMap, setVisibleMap] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isLoaded, setLoaded] = useState(false);
    const canEdit = useSelector(({ resume }) => resume.permission) === EDIT;

    useEffect(() => {
        if (isLoaded) {
            return;
        }

        if (visibleMap && searchPoints) {
            setLoading(true);

            import(/* webpackMode: "eager" */ 'Modules/Maps/showMap').then(({ default: showMap, defaults }) => {
                showMap(mapContainer.current, {
                    address: {
                        mapData: {
                            points: {
                                center: { ...searchPoints, zoom: 4 },
                                marker: searchPoints,
                            },
                        },
                    },
                    disableScrollZoom: true,
                    placemarkZIndex: defaults.placemarkZIndex + 1,
                    placemarkIcon: `${staticHost}/images/Components/VacancyMap/resume-placemark.svg`,
                    placemarkIconSize: [39, 39],
                    placemarkOffset: [-10, 12],
                    zoomControlPosition: {
                        left: 10,
                        top: 30,
                    },
                })
                    .then(() => {
                        setLoading(false);
                        setLoaded(true);
                    })
                    .catch(console.error);
            }, console.error);
        }
    }, [searchPoints, visibleMap, isLoaded, staticHost, hash, searchPointsByResumeHash, resume]);

    if (!(hash in searchPointsByResumeHash)) {
        return null;
    }

    return (
        <>
            <div>
                <VSpacing base={2} />
                <p>{trls[Map.trls.title]}</p>
                <BlokoLink appearance={LinkAppearance.Pseudo} onClick={() => setVisibleMap(!visibleMap)}>
                    {visibleMap ? trls[Map.trls.hide] : trls[Map.trls.show]}
                </BlokoLink>
                {isLoading && (
                    <>
                        {' '}
                        <Loading scale={LoadingScale.Small} />
                    </>
                )}
            </div>
            <div
                className={classnames('resume-map', { 'resume-map_hide': !(visibleMap && isLoaded) })}
                ref={mapContainer}
            />
            {visibleMap && isLoaded && canEdit && (
                <>
                    <VSpacing base={2} />
                    <Link
                        className="resume-block-edit resume-block-edit_capitalize"
                        to="/applicant/settings?from=resume-builder#address"
                    >
                        <Text Element="span" size={TextSize.Small}>
                            {trls[Map.trls.edit]}
                        </Text>
                    </Link>
                </>
            )}
        </>
    );
};

Map.trls = {
    title: 'resume.map.title',
    hide: 'resume.map.hide',
    show: 'resume.map.show',
    edit: 'rb.edit',
};

Map.propTypes = {
    trls: PropTypes.object,
};

export default translation(Map);
