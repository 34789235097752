import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    error: 'resume.notallfunctions.warning',
};

const ResumeViewHistoryErrorFetchingNotification: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.error]}</>;
export default {
    Element: translation(ResumeViewHistoryErrorFetchingNotification),
    kind: 'error',
    autoClose: false,
};
