import { FC, PropsWithChildren } from 'react';

import { DefaultLayout } from 'src/app/layouts/PageLayout';
import PageErrorWrapper from 'src/components/PageErrorWrapper';
import { useSelector } from 'src/hooks/useSelector';

import ResumeError from 'src/pages/ResumeView/components/View/ResumeError';

const ResumeErrorWrapper: FC<PropsWithChildren> = ({ children }) => {
    const resume = useSelector((state) => state.resume);

    if (resume?.error) {
        return (
            <DefaultLayout>
                <ResumeError viewLimit={resume?.viewLimit} />
            </DefaultLayout>
        );
    }

    return <PageErrorWrapper>{children}</PageErrorWrapper>;
};

export default ResumeErrorWrapper;
