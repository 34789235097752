export const CELL_PHONE = 'cell_phone';
export const HOME_PHONE = 'home_phone';
export const WORK_PHONE = 'work_phone';
export const EMAIL = 'email';

export const CELL_PHONE_TYPE = 'cell';

const preferredContactToPhoneType = {
    [CELL_PHONE]: CELL_PHONE_TYPE,
    [HOME_PHONE]: 'home',
    [WORK_PHONE]: 'work',
    [EMAIL]: EMAIL,
};

export const getPreferredPhoneType = (preferredContact) => {
    if (preferredContact in preferredContactToPhoneType) {
        return preferredContactToPhoneType[preferredContact];
    }
    return null;
};
