import { FC } from 'react';

import Tag from 'bloko/blocks/tagList/tag';
import { format } from 'bloko/common/trl';

import { useSelector } from 'src/hooks/useSelector';

import { isQuestionRendered } from 'src/pages/ResumeView/components/CredsView/utils';

interface QuestionAnswerProps {
    questionId: string;
    answers: string[];
}

const QuestionAnswer: FC<QuestionAnswerProps> = ({ questionId, answers }) => {
    const creds = useSelector(({ resume }) => resume?.creds);

    if (!creds || !isQuestionRendered(answers, creds)) {
        return null;
    }

    const renderAnswers = () =>
        answers
            .filter((answerId) => !creds.answers[answerId]?.skipAtResult)
            .map((answerId) => creds.answers[answerId]?.positiveTitle)
            .join(', ');

    return <Tag>{format(creds.questions[questionId]?.viewTitle || '{answers}', { '{answers}': renderAnswers() })}</Tag>;
};

export default QuestionAnswer;
