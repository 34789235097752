import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Text, { TextSize } from 'bloko/blocks/text';

import translation from 'src/components/translation';

const WantWork = ({ hide, trls }) => {
    if (hide) {
        return null;
    }

    return (
        <div className="resume__wantwork">
            <Text size={TextSize.Small}>{trls[WantWork.trls.content]}</Text>
        </div>
    );
};

WantWork.trls = {
    content: 'employer.resumeSearchResult.wishWork',
};

WantWork.propTypes = {
    hide: PropTypes.bool,
    trls: PropTypes.object,
};

export default connect((state) => ({
    hide: !state.resume?.wantWork,
}))(translation(WantWork));
