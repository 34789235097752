import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import ResumeAreaWithDistricts from 'src/pages/ResumeView/components/View/ResumeAreaWithDistricts';

const TrlKeys = {
    title: 'resume.jobArea.title',
};

const ResumeJobArea: TranslatedComponent = ({ trls }) => {
    const relocationAreaExtended = useSelector(({ resume }) => resume?.relocationAreaExtended.value);
    const isPrintVersion = useSelector(({ printVersion }) => printVersion.isPrintVersion);

    if (!relocationAreaExtended || relocationAreaExtended.length === 0) {
        return null;
    }

    return (
        <div>
            <VSpacing base={6} />
            <Text importance={TextImportance.Tertiary}>{trls[TrlKeys.title]}</Text>
            <VSpacing base={4} />
            {relocationAreaExtended.map((area) => (
                <div key={area.id} data-qa={`resume-relocationArea-${area.id}`}>
                    <ResumeAreaWithDistricts area={area} withToggle={!isPrintVersion} />
                    <VSpacing base={4} />
                </div>
            ))}
        </div>
    );
};

export default translation(ResumeJobArea);
