import { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Loading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';
import Modal, { ModalHeader, ModalTitle, ModalError } from 'bloko/blocks/modal';
import urlParser from 'bloko/common/urlParser';

import CdnImg from 'src/components/CdnImg';
import translation from 'src/components/translation';

class ResumeImageLoader extends PureComponent {
    state = {
        modalVisible: false,
        error: false,
        imageLoaded: false,
    };

    bigImageSrc = urlParser(this.props.imageResizingCdnHost + this.props.big);

    openModalImage = () => {
        this.setState({ modalVisible: true });
        const image = new Image();
        image.src = this.bigImageSrc.href;

        image.addEventListener('load', () => {
            this.setState({ imageLoaded: true });
        });

        image.addEventListener('error', () => {
            this.setState({ error: true });
        });
    };

    closeModalImage = () => this.setState({ modalVisible: false });

    renderImage = () => {
        const { imageLoaded, error } = this.state;
        if (error) {
            return null;
        }
        if (imageLoaded) {
            return (
                <div className="resume-portfolio-modal-image-wrapper">
                    <CdnImg className="resume-portfolio-modal-image" path={this.props.big} alt="" />
                </div>
            );
        }
        return (
            <div className="resume-portfolio-loading">
                <Loading initial={LoadingColor.Gray80} scale={LoadingScale.Small} />
            </div>
        );
    };

    render() {
        const { title, trls } = this.props;
        const { modalVisible, error } = this.state;
        return (
            <Fragment>
                <a className="resume__portfolio-item" onClick={this.openModalImage}>
                    <CdnImg loading="lazy" path={this.props.preview} alt="" />
                </a>
                <Modal visible={modalVisible} onClose={this.closeModalImage}>
                    {title && (
                        <ModalHeader>
                            <ModalTitle>{title}</ModalTitle>
                        </ModalHeader>
                    )}
                    {this.renderImage()}
                    <ModalError visible={error}>{trls[ResumeImageLoader.trls.error]}</ModalError>
                </Modal>
            </Fragment>
        );
    }
}

ResumeImageLoader.propTypes = {
    preview: PropTypes.string,
    big: PropTypes.string,
    title: PropTypes.string,
    trls: PropTypes.object,
    imageResizingCdnHost: PropTypes.string,
};

ResumeImageLoader.trls = {
    error: 'resume.block.portfolio.load.error',
};

export default connect((state) => ({
    imageResizingCdnHost: state.config.imageResizingCdnHost,
}))(translation(ResumeImageLoader));
