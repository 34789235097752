import { useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';

import { Button, Link, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Form from 'src/components/Form';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import CheckablePlate from 'src/pages/ResumeView/components/View/ResumeSidebar/Comments/HiringManagerComment/CheckablePlate';
import TextareaField from 'src/pages/ResumeView/components/View/ResumeSidebar/Comments/HiringManagerComment/TextareaField';
import { useSendCommentSubmit } from 'src/pages/ResumeView/components/View/ResumeSidebar/Comments/HiringManagerComment/api/useSendCommentSubmit';
import { HiringManagerCommentVerdict } from 'src/pages/ResumeView/components/View/ResumeSidebar/Comments/HiringManagerComment/types';

import styles from './index.less';

const TrlKeys = {
    add: 'resume.comments.hiringManager.add',
    submit: 'resume.comments.hiringManager.submit',
    cancel: 'resume.comments.hiringManager.cancel',
    accept: 'resume.comments.hiringManager.accept',
    reject: 'resume.comments.hiringManager.reject',
    candidateAccepted: 'resume.comments.hiringManager.candidateAccepted',
    candidateRejected: 'resume.comments.hiringManager.candidateRejected',
};

const HiringManagerComment: TranslatedComponent = ({ trls }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const { vacancyId, t: topicId } = useSelector((state) => state.router.location.query);
    const resumeHash = useSelector((state) => state.resume?.hash);

    const handleSubmit = useSendCommentSubmit({ trls, setIsExpanded, vacancyId, topicId, resumeHash });

    if (!isExpanded) {
        return (
            <Link
                onClick={(e) => {
                    e.preventDefault();
                    setIsExpanded(true);
                }}
                typography="label-2-regular"
                style="accent"
            >
                {trls[TrlKeys.add]}
            </Link>
        );
    }

    return (
        <FinalForm onSubmit={handleSubmit}>
            {({ handleSubmit, form, submitting, values }) => (
                <Form onSubmit={handleSubmit}>
                    <Field<HiringManagerCommentVerdict> name="verdict">
                        {({ input }) => {
                            const onClick = (ev: React.ChangeEvent<HTMLInputElement>) => {
                                const value = ev.currentTarget.name;
                                input.onChange(input.value === value ? null : value);
                            };
                            return (
                                <div className={styles.radiosWrapper}>
                                    <CheckablePlate name="accept" checked={input.value === 'accept'} onClick={onClick}>
                                        {trls[TrlKeys.accept]}
                                    </CheckablePlate>
                                    <CheckablePlate name="reject" checked={input.value === 'reject'} onClick={onClick}>
                                        {trls[TrlKeys.reject]}
                                    </CheckablePlate>
                                </div>
                            );
                        }}
                    </Field>
                    <VSpacing default={16} />
                    <TextareaField verdict={values.verdict} />
                    <VSpacing default={16} />
                    <div className={styles.buttonsWrapper}>
                        <Button mode="primary" size="small" style="accent" type="submit" loading={submitting}>
                            {trls[TrlKeys.submit]}
                        </Button>
                        <Button
                            mode="tertiary"
                            size="small"
                            style="neutral"
                            onClick={() => {
                                form.restart();
                                setIsExpanded(false);
                            }}
                            loading={submitting}
                        >
                            {trls[TrlKeys.cancel]}
                        </Button>
                    </div>
                </Form>
            )}
        </FinalForm>
    );
};

export default translation(HiringManagerComment);
