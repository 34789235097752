import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Column, { ColumnsRow } from 'bloko/blocks/column';

import ProfileBanner from 'src/pages/ResumeView/components/ProfileBanner';
import { EDIT } from 'src/utils/constants/resumePermission';

import ResumePublishSmall from 'src/pages/ResumeView/components/View/ResumeSidebar/ResumePublishSmall';

const ResumeSidebarSmall = ({ percent, canEdit }) => {
    if (percent === 0 || !canEdit) {
        return null;
    }

    return (
        <ColumnsRow>
            <ProfileBanner mobileOnly bottomSpacing={4} />

            <div className="noprint">
                <Column xs="4" s="8" m="0" l="0" container>
                    <div className="resume-wrapper">
                        <div className="resume-sidebar-background" />
                        <div>
                            <ResumePublishSmall />
                        </div>
                    </div>
                </Column>
            </div>
        </ColumnsRow>
    );
};

ResumeSidebarSmall.propTypes = {
    canEdit: PropTypes.bool,
    percent: PropTypes.number,
};

export default connect((state) => ({
    canEdit: state.resume?.permission === EDIT,
    percent: state.resume?.percent,
}))(ResumeSidebarSmall);
