import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { OK } from 'http-status-codes';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import Text, { TextSize } from 'bloko/blocks/text';
import BlokoTranslateGuard from 'bloko/blocks/translateGuard';

import { useNotification } from 'src/components/Notifications/Provider';
import { CELL_PHONE_TYPE } from 'src/components/ResumeContacts/preferredContacts';
import getContactPhones from 'src/components/ResumeView/getContactPhones';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';

import ResumePhoneVerified from 'src/pages/ResumeView/components/ResumeHeader/Contacts/ResumePhoneVerified';

const ShowContacts = ({ phone, setContactsVisibility, trls }) => {
    const { created, hash: resumeHash, id: resumeId, simHash } = useSelector(({ resume }) => resume);
    const vacancyId = useSelector(({ router }) => router.location.query?.vacancyId);
    const topicId = useSelector(({ router }) => router.location.query?.t);
    const dispatch = useDispatch();
    const { addNotification } = useNotification();

    const [loading, turnLoadingOn, turnLoadingOff] = useOnOffState(false);

    const showContacts = useCallback(
        (event) => {
            turnLoadingOn();
            dispatch(
                getContactPhones(
                    { created, resumeHash, simHash, event, goal: 'Contacts_Phone', topicId },
                    addNotification
                )
            )
                .then(({ status }) => {
                    if (status === OK) {
                        Analytics.sendHHEvent('resumeShowContacts', {
                            goal: 'ShowContacts',
                            resumeId,
                            vacancyId,
                        });
                        setContactsVisibility(true);
                    } else {
                        turnLoadingOff();
                    }
                })
                .catch(console.error);
        },
        [
            addNotification,
            created,
            dispatch,
            resumeHash,
            resumeId,
            setContactsVisibility,
            simHash,
            topicId,
            turnLoadingOff,
            turnLoadingOn,
            vacancyId,
        ]
    );

    return (
        <div data-qa="resume-serp_resume-item-content">
            <div data-qa="resume-contacts-phone">
                {phone.type === CELL_PHONE_TYPE && (
                    <ResumePhoneVerified verified={phone.verified} needVerification={phone.needVerification} />
                )}
                <BlokoTranslateGuard>
                    <span>{phone.formatted.slice(0, 11)} &#8230; </span>
                    {loading ? (
                        <Loading scale={LoadingScale.Small} />
                    ) : (
                        <BlokoTranslateGuard>
                            <Link
                                appearance={LinkAppearance.Pseudo}
                                data-qa="response-resume_show-phone-number"
                                onClick={showContacts}
                            >
                                {trls[ShowContacts.trls.show]}
                            </Link>
                        </BlokoTranslateGuard>
                    )}
                </BlokoTranslateGuard>
                {phone.verified && (
                    <div className="resume-search-item-phone-verification-status">
                        <Text size={TextSize.Small}>{trls[ShowContacts.trls.phoneVerified]}</Text>
                    </div>
                )}
            </div>
        </div>
    );
};

ShowContacts.trls = {
    phoneVerified: 'resume.phoneVerified',
    show: 'resume.field.contacts.show',
};

ShowContacts.propTypes = {
    phone: PropTypes.shape({
        city: PropTypes.string,
        comment: PropTypes.string,
        country: PropTypes.string,
        formatted: PropTypes.string,
        number: PropTypes.string,
        raw: PropTypes.string,
        type: PropTypes.string,
        verified: PropTypes.bool,
        needVerification: PropTypes.bool,
    }).isRequired,
    setContactsVisibility: PropTypes.func.isRequired,
    trls: PropTypes.object.isRequired,
};

export default translation(ShowContacts);
