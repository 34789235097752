import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    addPhoto: 'rb.edit.addPhoto',
};

const AddPhoto: TranslatedComponent = ({ trls }) => {
    const hash = useSelector((state) => state.resume?.hash);

    if (!hash) {
        return null;
    }

    return (
        <div className="resume-media resume-media_empty">
            <div className="resume-media__placeholder">
                <BlokoLink
                    kind={LinkKind.Secondary}
                    Element={Link}
                    data-qa="resume-add-photo"
                    to={`/applicant/resumes/edit/photo?resume=${hash}`}
                >
                    <span className="resume-media__link">{trls[TrlKeys.addPhoto]}</span>
                </BlokoLink>
            </div>
        </div>
    );
};

export default translation(AddPhoto);
