import { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';

import translation from 'src/components/translation';

const TalantixResumeImportFailedNotification = ({ type, trls }) => {
    useEffect(() => {
        Analytics.sendEvent('talantix', 'import_error', `show_error_${type}`);
    }, [type]);

    const trlKeys = TalantixResumeImportFailedNotification.trls[type] || {};
    return (
        <Fragment>
            <NotificationHeading>{trls[trlKeys.title]}</NotificationHeading>
            {trls[trlKeys.text]}
            {trlKeys.mail !== undefined && (
                <Fragment>
                    {' '}
                    <BlokoLink disableVisited Element={Link} to={`mailto:${trls[trlKeys.mail]}`}>
                        {trls[trlKeys.mail]}
                    </BlokoLink>
                </Fragment>
            )}
        </Fragment>
    );
};

TalantixResumeImportFailedNotification.propTypes = {
    type: PropTypes.string,
    trls: PropTypes.object,
};

TalantixResumeImportFailedNotification.trls = {
    network: {
        title: 'talantix.import.notification.error.network.title',
        text: 'talantix.import.notification.error.network.text',
    },
    account: {
        title: 'talantix.import.notification.error.account.title',
        text: 'talantix.import.notification.error.account.text',
    },
    system: {
        title: 'talantix.import.notification.error.system.title',
        text: 'talantix.import.notification.error.system.text',
        mail: 'talantix.import.notification.error.system.mail',
    },
    api: {
        title: 'talantix.import.notification.error.api.title',
        text: 'talantix.import.notification.error.api.text',
    },
    permission: {
        title: 'talantix.import.notification.error.permission.title',
        text: 'talantix.import.notification.error.permission.text',
    },
};

export default translation(TalantixResumeImportFailedNotification);
