import { FC, MutableRefObject } from 'react';

import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';

interface SkillsSurveyProps {
    blockRef?: MutableRefObject<HTMLDivElement>;
}

const SkillsSurvey: FC<SkillsSurveyProps> = ({ blockRef }) => {
    return (
        <div data-qa="resume-block-skill-survey-content" ref={blockRef}>
            <ContainerForMicroFrontend place="skills-view" />
        </div>
    );
};

export default SkillsSurvey;
