import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import resumePrintButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_print_button_click';
import Button from 'bloko/blocks/button';
import HoverTip, { TipLayer } from 'bloko/blocks/drop/Tip/HoverTip';
import { PrinterScaleSmall, IconColor } from 'bloko/blocks/icon';
import urlParser from 'bloko/common/urlParser';

import loadContacts from 'src/components/ResumeView/LoadContacts';
import translation from 'src/components/translation';
import useIsClient from 'src/hooks/useIsClient';

const ButtonPrint = ({ trls, validate, resumeId, location }) => {
    const isClient = useIsClient();

    let url = { href: '' };
    url = urlParser(location.pathname + location.search);
    url.params.print = [true];

    const onClick = (event) => {
        resumePrintButtonClick({ vacancyId: location.query.vacancyId, resumeId });
        validate(event, 'Print')
            .then(() => window.open(url.href, '_blank', 'noopener'))
            .catch(console.error);
    };

    return (
        <HoverTip
            host={isClient ? document.body : null}
            layer={TipLayer.Overlay}
            render={() => trls[ButtonPrint.trls.print]}
        >
            <Button
                icon={<PrinterScaleSmall initial={IconColor.Gray50} />}
                Element={'button'}
                onClick={onClick}
                data-qa="resume-print-view"
                aria-label={trls[ButtonPrint.trls.print]}
            />
        </HoverTip>
    );
};

ButtonPrint.propTypes = {
    trls: PropTypes.object,
    validate: PropTypes.func.isRequired,
    resumeId: PropTypes.string,
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
        query: PropTypes.object,
    }),
};

ButtonPrint.trls = {
    print: 'topbuttons.resume.print',
};

export default connect((state) => ({ location: state.router.location, resumeId: state.resume?.id }))(
    loadContacts(translation(ButtonPrint))
);
