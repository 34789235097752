import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Analytics, { VendorAnalytics } from '@hh.ru/analytics-js';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { H3 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';

import InfoBlockSubstrate, { InfoBlockSubstrateKind } from 'src/components/InfoBlockSubstrate';
import translation from 'src/components/translation';

const sendAnalyticsResumeToRegister = () => {
    VendorAnalytics.eventToStorage('employer', 'to_register', 'resume');
    Analytics.sendHHEventButtonClick('resume-registration-button');
};

const ResumeAnonymousAccess = ({ backUrl, trls }) => {
    return (
        <InfoBlockSubstrate kind={InfoBlockSubstrateKind.Secondary}>
            <H3>{trls[ResumeAnonymousAccess.trls.title]}</H3>
            <VSpacing base={4} />
            <Button
                Element={Link}
                kind={ButtonKind.Primary}
                to={`/auth/employer/?backurl=${encodeURIComponent(backUrl)}`}
                onClick={sendAnalyticsResumeToRegister}
            >
                {trls[ResumeAnonymousAccess.trls.register]}
            </Button>
        </InfoBlockSubstrate>
    );
};

ResumeAnonymousAccess.trls = {
    title: 'resumeView.anonymous.access.v2',
    register: 'resumeView.anonymous.register',
};

ResumeAnonymousAccess.propTypes = {
    backUrl: PropTypes.string,
    trls: PropTypes.object,
};

export default connect((state) => ({
    backUrl: state.request.url,
}))(translation(ResumeAnonymousAccess));
