// tempexp_HH-23996_file
import { FC } from 'react';

import { Card, Text, VSpacing } from '@hh.ru/magritte-ui';
import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import { H3 } from 'bloko/blocks/header';

import { HrlinkBannerProps } from 'src/components/HrlinkBanner/types';

const HrlinkBannerNative: FC<HrlinkBannerProps> = ({ url, onButtonClick }) => {
    return (
        <Card>
            <H3>Кандидата можно нанять онлайн</H3>
            <VSpacing default={16} />
            <Text typography="label-3-regular" style="secondary">
                Нанимайте и подписывайте кадровые документы онлайн за 1 минуту
            </Text>
            <VSpacing default={16} />
            <Button
                Element="a"
                onClick={onButtonClick}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                appearance={ButtonAppearance.Filled}
                kind={ButtonKind.Primary}
            >
                Подключить кадровый ЭДО
            </Button>
        </Card>
    );
};

export default HrlinkBannerNative;
