import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import Text, { TextSize } from 'bloko/blocks/text';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import FormatMoney from 'src/components/FormatMoney';
import SearchType from 'src/components/NovaFilters/SearchType';
import translation from 'src/components/translation';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import useSearchResultShown from 'src/hooks/useSearchResultShown';

const Features = {
    collectResumeViewDuration: 'collect_resume_view_duration',
};

function renderName(resume) {
    const firstName = resume.firstName?.[0]?.string;
    const middleName = resume.middleName?.[0]?.string;
    const lastName = resume.lastName?.[0]?.string;

    if (!firstName && !middleName && !lastName) {
        return null;
    }

    return [firstName, middleName, lastName].join(' ');
}

function renderExperience(resume) {
    if (!resume.shortExperience || !resume.shortExperience[0]) {
        return null;
    }

    let companyBlock = '';
    if (resume.shortExperience[0].companyName) {
        companyBlock = (
            <Fragment>
                <strong>{resume.shortExperience[0].companyName}</strong>
                {', '}
            </Fragment>
        );
    }

    return (
        <Fragment>
            {companyBlock}
            {resume.shortExperience[0].position}
        </Fragment>
    );
}

const renderSalary = (resume, trls) => {
    if (!resume.salary || !resume.salary[0] || !resume.salary[0].amount) {
        return trls[RelatedResume.trls.compensationNotSet];
    }

    return <FormatMoney currency={resume.salary[0].currency}>{resume.salary[0].amount}</FormatMoney>;
};

function RelatedResume({ resume, trls }) {
    const collectResumeViewDurationFeature = useFeatureEnabled(Features.collectResumeViewDuration);

    const queryParams = {};
    if (collectResumeViewDurationFeature && resume.searchRid) {
        queryParams.searchRid = resume.searchRid;
    }

    const searchResultShown = useSearchResultShown({
        searchType: SearchType.Resume,
        itemId: resume._attributes.hash,
        searchRid: resume.searchRid,
    });

    return (
        <li className="related-item" data-qa="similar-resumes-block-item">
            <ElementShownAnchor fn={searchResultShown}>
                <Text size={TextSize.Large}>
                    <div data-qa="similar-resumes-block-item-name">{renderName(resume)}</div>
                    <div>
                        <BlokoLink
                            to={`/resume/${resume._attributes.hash}?hhtmFromLabel=related_resumes`}
                            Element={Link}
                            data-qa="similar-resumes-block-item-link"
                            additionalQueryParams={queryParams}
                        >
                            {/* тайтла может не быть, если это незавершенное дублированное резюме*/}
                            {resume.title[0]?.string ?? trls[RelatedResume.trls.emptyTitle]}
                        </BlokoLink>
                    </div>
                    <div data-qa="similar-resumes-block-item-salary">{renderSalary(resume, trls)}</div>
                    <div className="related-item__experience" data-qa="similar-resumes-block-item-experience">
                        <Text>{renderExperience(resume)}</Text>
                    </div>
                </Text>
            </ElementShownAnchor>
        </li>
    );
}

const resumeFieldPropType = PropTypes.arrayOf(
    PropTypes.shape({
        string: PropTypes.string,
    })
);

RelatedResume.propTypes = {
    resume: PropTypes.shape({
        _attributes: PropTypes.shape({
            hash: PropTypes.string,
        }),
        title: resumeFieldPropType,
        middleName: resumeFieldPropType,
        lastName: resumeFieldPropType,
        firstName: resumeFieldPropType,
        salary: PropTypes.arrayOf(
            PropTypes.shape({
                currency: PropTypes.string,
                amount: PropTypes.number,
            })
        ),
        shortExperience: PropTypes.arrayOf(
            PropTypes.shape({
                position: PropTypes.string,
                companyName: PropTypes.string,
            })
        ),
        searchRid: PropTypes.string,
    }),
    trls: PropTypes.object,
};

RelatedResume.trls = {
    compensationNotSet: 'vacancySearchResults.compensationNotSet',
    emptyTitle: 'resume.title.empty',
};

export default translation(RelatedResume);
