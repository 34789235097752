import PropTypes from 'prop-types';

import Column from 'bloko/blocks/column';
import { CalendarScaleSmall } from 'bloko/blocks/icon';
import { format } from 'bloko/common/trl';

import formatDate from 'Modules/formatDate';
import translation from 'src/components/translation';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';

const SidebarInterview = ({ currentInterview, trls, isTab = false }) => {
    const { mSidebar } = useGetMColumnSize();
    const interviewDate = new Date(currentInterview.scheduledTime);
    const [time, date] = formatDate(interviewDate, 'HH:mm;dd.MM').split(';');
    const content = (
        <div className="resume-sidebar-interview">
            <div className="resume-sidebar-interview__icon">
                <CalendarScaleSmall />
            </div>
            <div suppressHydrationWarning>
                {format(trls[SidebarInterview.trls.interviewAt], {
                    '{0}': date,
                    '{1}': time,
                })}
            </div>
        </div>
    );

    if (isTab) {
        return content;
    }

    return (
        <Column xs="0" s="4" m={mSidebar} l="4">
            <div className="resume-sidebar-section resume-sidebar-section_first" data-qa="resume-interview">
                {content}
            </div>
        </Column>
    );
};

SidebarInterview.trls = {
    interviewAt: 'resume.interview.schedule.planned',
};

SidebarInterview.propTypes = {
    isTab: PropTypes.bool,
    currentInterview: PropTypes.object,
    trls: PropTypes.object,
};

export default translation(SidebarInterview);
